import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPersonalAreaTour } from 'redux/tours/toursSlice';
import { selectPersonalAreaTour } from 'redux/tours/selectors';
import { selectApplications } from 'redux/landing/landingSlice';
import { TourWrapper } from 'containers/auth/PersonalArea/style';
import { Text } from '@flexisaf/flexibull2';
import { Theme } from 'utils/theme';
import styled from 'styled-components';

const usePersonAreaTour = () => {
  const dispatch = useDispatch()
  const personalAreaTour = useSelector(selectPersonalAreaTour);
  const { org } = useSelector(selectApplications);

  const steps = [
  {
    content: (
      <TourWrapper primaryColor={org?.themeColor ? org.themeColor : Theme.PrimaryColor}>
        <Text className="title">Hello There  <span role="img" aria-label='waving emoji'>👋</span></Text>
        <p className="body">
          Welcome to your personal area, where you can view and manage your applications.
        </p>
        <p className="body">
          Would you like to take a quick tour?
        </p>
        <p className="body">
          You can also <a target="_blank" rel="noopener noreferrer" href="https://support.safapply.com/support/solutions/articles/67000675234-getting-started">watch 2 minutes  intro video</a>.
        </p>
      </TourWrapper>
    ),
    placement: 'center',
    target: '#personal-area',
  },
  {
    content: <TourWrapper primaryColor={org?.themeColor ? org.themeColor : Theme.PrimaryColor}>
        
        <p className="body">
          Search and apply for applications here.
        </p>
      </TourWrapper>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 10,
    target: '#banner-section',
  },
  {
    content: <TourWrapper primaryColor={org?.themeColor ? org.themeColor : Theme.PrimaryColor}>
        
        <p className="body">
          Once you have successfully applied, your applications will appear here. You can click into any of them to get more details and perform actions.
        </p>
        <ApplicationExampleImg src="/images/application-example.png" style={{width: '100%'}} />

      </TourWrapper>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 10,
    target: '#view-applications',
  },
  {
    content: <TourWrapper primaryColor={org?.themeColor ? org.themeColor : Theme.PrimaryColor}>
        
        <p className="body">
          Can’t find an application? search and filter them here
        </p>
      </TourWrapper>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 10,
    target: '#dashboard-search',
  },
  {
    content: <TourWrapper primaryColor={org?.themeColor ? org.themeColor : Theme.PrimaryColor}>
        
        <p className="body">
          View and manage your transactions. You can see the status and also retry pending transactions.
        </p>
      </TourWrapper>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 10,
    target: '#transactions-history',
  },
  {
    content: (
      <TourWrapper primaryColor={org?.themeColor ? org.themeColor : Theme.PrimaryColor}>
        <Text className="title"><span role="img" aria-label='confetti emoji'>🎉</span> And that's it...</Text>
        <p className="body">
          Feel free to explore more areas of your dashboard. Enjoy!
        </p>
        <p className="body">Click <strong>Tour</strong> above if you want to restart tour.</p>
      </TourWrapper>
    ),
    // locale: { skip: 'Skip'},
    placement: 'center',
    target: '#personal-area',
  },
  ]

  const runTour = useCallback(() => {
    dispatch(setPersonalAreaTour({
      ...personalAreaTour,
      run: true,
      runCount: personalAreaTour.runCount + 1
    }))
  }, [personalAreaTour, dispatch])

  useEffect(() => {
    if (steps && personalAreaTour.runCount === 0) {
      runTour()
    }

  }, [steps, dispatch, personalAreaTour, runTour])

  const handleCallback = (callbackProps) => {
    if(callbackProps.status === "finished" || callbackProps.status === "skipped") {
      dispatch(setPersonalAreaTour({
        ...personalAreaTour,
        run: false,
      }))
    }
  }

  

  return {
    tour: {
      run: personalAreaTour.run,
      steps,
    },
    runTour,
    handleCallback
  }
}

export default usePersonAreaTour;

const ApplicationExampleImg = styled.img`
  width: 100%;
`;