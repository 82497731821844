import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalAreaTour: {
    run: false,
    runCount: 0
  }
}

export const toursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setPersonalAreaTour: (state, action) => {
      state.personalAreaTour = {
        ...action.payload
      }
    }
  }
});

export const { setPersonalAreaTour } = toursSlice.actions;

const persistConfig = {
  key: 'tours',
  storage,
  whitelist: ['personalAreaTour'],
};
const toursReducer = toursSlice.reducer;

export default persistReducer(persistConfig, toursReducer);