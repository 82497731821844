import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { Theme } from "utils/theme";
import { Select } from "@flexisaf/flexibull2";

export const FooterWrapper = styled.div`
  padding-top: 5px;
  width: 650px;
  border-top: 1px solid;
  position: absolute;
  z-index: 1000;

  // write media queries for various height to alter bottom property
  // write media queries for various width to alter width property

  @media (max-width: 1440px) {
    width: 650px;
  }
  @media (max-width: 1280px) {
    width: 600px;
  }
  @media (max-width: 1024px) {
    width: 460px;
  }
  @media (max-width: 912px) {
    width: 750px;
  }
  @media (max-width: 820px) {
    width: 650px;
  }
  @media (max-width: 768px) {
    width: 350px;
  }
  @media (max-width: 540px) {
    width: 325px;
  }
  @media (max-width: 425px) {
    width: 300px;
  }
  @media (max-width: 375px) {
    width: 300px;
  }
  @media (max-width: 320px) {
    width: 280px;
  }

  // write media queries for various height to alter bottom or top property
  @media (max-height: 600px) {
    position: absolute;
    top: 600px;
  }
  @media (max-height: 653px) {
    position: absolute;
    top: 650px;
  }
  @media (max-height: 667px) {
    position: absolute;
    top: 660px;

    & .flexi-menu {
      max-height: 25px;
    }
  }
  @media (max-height: 720px) {
    position: absolute;
    top: 680px;
  }
  @media (max-height: 740px) {
    position: absolute;
    top: 690px;
  }
  @media (max-height: 800px) {
    position: absolute;
    top: 700px;
  }
  @media (max-height: 844px) {
    position: absolute;
    top: 710px;
  }
  @media (max-height: 851px) {
    position: absolute;
    top: 710px;
  }
  @media (max-height: 856px) {
    position: absolute;
    top: 56px;
  }
  @media (max-height: 856px) {
    position: absolute;
    top: 56px;
  }
  @media (max-height: 896px) {
    position: absolute;
    top: 740px;
  }
  @media (max-height: 914px) {
    position: absolute;
    top: 720px;
  }
  @media (max-height: 915px) {
    position: absolute;
    top: 720px;
  }
  & .form-footer {
    position: absolute;
    top: 370px;
  }
  @media (min-height: 1024px) {
    position: absolute;
    top: 800px;
  }
  @media (min-height: 1180px) {
    position: absolute;
    top: 880px;
  }

  @media (min-height: 1368px) {
    position: absolute;
    top: 940px;
  }
`;

export const NecoSelect = styled(Select)`
  & .flexi__menu {
    max-height: 250px;
    overflow-y: auto;
    @media (max-height: 1024px) {
      max-height: 200px;
    }
    @media (max-height: 915px) {
      max-height: 90px;
    }
    @media (max-height: 896px) {
      max-height: 100px;
    }
    @media (max-height: 851px) {
      max-height: 130px;
    }
    @media (max-height: 789px) {
      max-height: 230px;
    }
    @media (max-height: 740px) {
      max-height: 124px;
    }
    @media (max-height: 720px) {
      max-height: 130px;
    }
    @media (max-height: 667px) {
      max-height: 120px;
    }
    @media (max-height: 653px) {
      max-height: 100px;
    }
  }
  & .flexi-select-label {
    min-width: 110px;
  }
`;

export const ModalBodyWrapper = styled.div`
  margin: auto;
  width: 50vw;
  min-width: fit-content;
  height: 800px;
  background-color: #fff;
  max-height: 100vh;
  overflow-y: scroll;
`;

export const Wrapper = styled.div`
  margin: 10px 10px 0;
  padding: 10px 30px 0 30px;
  @media (max-width: 480px) {
    padding: 0 30px;
    max-height: 420px;
    overflow-y: scroll;
  }
  @media (max-height: 680px) {
    padding: 0 30px;
    max-height: 280px;
    overflow-y: scroll;
  }
  @media (max-height: 750px) {
    margin: -16px 10px 0;
    padding: 0 30px;
    max-height: 420px;
    overflow-y: scroll;
    & .mobile {
      margin-top: -12px;
    }
  }
  @media (min-height: 751px) {
    padding: 0 30px;
    max-height: 400px;
    overflow-y: scroll;
  }
`;

export const RadioButtonWrapper = styled.div`
  max-width: 350px;
  margin: auto;
  padding: 8px 0;
  border: 1px solid ${Theme.PrimaryGrey};
  border-radius: 5px;
  text-align: left;
  ${(props) =>
    props.borderColour
      ? css`
          border: 1px solid ${Theme.PrimaryColor};
        `
      : css`
          border: 1px solid ${Theme.PrimaryGrey};
        `};
`;

export const FormWrapper = styled.div`
  @media (max-height: 600px) {
    max-height: 420px;
    overflow-y: auto;
  }
  @media (max-height: 653px) {
    max-height: 420px;
    overflow-y: auto;
  }
  @media (max-height: 667px) {
    max-height: 420px;
    overflow-y: auto;
  }
  @media (max-height: 720px) {
    max-height: 1200px;
    overflow-y: auto;
  }
  @media (max-height: 740px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 800px) {
    max-height: 1200px;
    overflow-y: auto;
  }
  @media (max-height: 844px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 851px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 856px) {
    max-height: 1200px;
    overflow-y: auto;
  }
  @media (max-height: 856px) {
    max-height: 1200px;
    overflow-y: auto;
  }
  @media (max-height: 896px) {
    max-height: 440px;
    overflow-y: auto;
  }
  @media (max-height: 914px) {
    max-height: 440px;
    overflow-y: auto;
  }
  @media (max-height: 915px) {
    max-height: 440px;
    overflow-y: auto;
  }

  @media (min-height: 1024px) {
    max-height: 1200px;
    overflow-y: auto;
  }
  @media (min-height: 1180px) {
    max-height: 1200px;
    overflow-y: auto;
  }

  @media (min-height: 1368px) {
    max-height: 1200px;
    overflow-y: auto;
  }
`;

export const NecoWrapper = styled.div`
  margin: auto;
  max-width: 70vw;
  overflow-y: auto;
  thead {
    background: ${(props) => transparentize(0.4, props.theme.PrimaryFade)};
  }
`;

export const NecoResultMainWrapper = styled.div`
  @media (max-height: 600px) {
    max-height: 420px;
    overflow-y: auto;
  }
  @media (max-height: 653px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 667px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 720px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 740px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 800px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 844px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 851px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 856px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 856px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 896px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (max-height: 914px) {
    max-height: 440px;
    overflow-y: auto;
  }
  @media (max-height: 915px) {
    max-height: 480px;
    overflow-y: auto;
  }

  @media (min-height: 1024px) {
    max-height: 480px;
    overflow-y: auto;
  }
  @media (min-height: 1180px) {
    max-height: 480px;
    overflow-y: auto;
  }

  @media (min-height: 1368px) {
    max-height: 480px;
    overflow-y: auto;
  }
`;
