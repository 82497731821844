import React from "react";
import { Box, Text, Grid, Button } from "@flexisaf/flexibull2";
import { useSelector, useDispatch } from "react-redux";
import {
  selectNeco,
  setStep,
  setVerificationStep,
  fetchCandidateInfo,
  fetchCandidateResult,
  setIsSecondSitting
} from "redux/neco/necoSlice";
import { selectPersonalArea } from "redux/auth/personalAreaSlice";
import { Theme } from "utils/theme";
import { FooterWrapper } from "./neco.style";

const NecoVerificationFooter = ({
  applySuccess,
  prev,
  next,
  isUsingNeco,
  hasPayment,
  handlePaymentSuccess,
  hasTwosittings,
}) => {
  const dispatch = useDispatch();
  const { currentCandidate, currentApplication } =
    useSelector(selectPersonalArea);
  const { candidateId, allExams, isLoadingNeco, isSecondSitting } = useSelector(selectNeco);

  function handleGoToForm(){
    hasPayment ? handlePaymentSuccess() : applySuccess(candidateId, currentCandidate); 
  }

  function handleProceed(next) {
    if (next === 2) {
      isUsingNeco ? dispatch(setStep(next)) : handleGoToForm();
    }
    if (next === 3) {
      allExams.map((payload) => dispatch(fetchCandidateInfo(payload)));
    }
    if (next === 4) {
      allExams.map((payload) =>
        dispatch(
          fetchCandidateResult({
            ...payload,
            candidateId: candidateId,
            applicationId: currentApplication?.id,
          })
        )
      );
    }
    if (next === 5) {
      if (hasTwosittings && !isSecondSitting) {
        dispatch(setStep(2));
        dispatch(setIsSecondSitting(true));
      } else {
        dispatch(setStep(next));
      }
    }
    if (next === 6) {
      dispatch(setIsSecondSitting(false));
      handleGoToForm();
    }
  }
  return (
      <Box
      pad="20px 25px 10px"
    >
      <FooterWrapper>
      <Grid default="repeat(2, 1fr)" sm="repeat(2, 1fr)">
        <Box pad="5px" align="left" style={{ cursor: "pointer" }}>
          <Text onClick={() => dispatch(setVerificationStep(false))}>
            Cancel
          </Text>
        </Box>
        <Box pad="5px" align="right" style={{ cursor: "pointer" }}>
          <Grid default="repeat(2, 1fr)" sm="repeat(2, 1fr)" gap="2px">
            <Text
              bold
              onClick={() =>
                prev !== 0
                  ? dispatch(setStep(prev))
                  : dispatch(setVerificationStep(false))
              }
            >
              <i className="safapply-left" />
              Go back
            </Text>
            <Button
              disabled={isUsingNeco === null}
              progress={isLoadingNeco}
              onClick={() => handleProceed(next)}
              spaceLeft="32px"
              color={Theme.PrimaryColor}
              style={{ overflow: "visible" }}
            >
              Proceed
            </Button>
          </Grid>
        </Box>
      </Grid>
      </FooterWrapper>
    </Box>
  );
};

export default NecoVerificationFooter;
