import groupBy from "lodash/groupBy";
import request from "utils/request";
import config from "utils/config";
import { convertArrayToObject, HIERARCHICAL_GROUP } from 'utils/helpers';

export const getOrg = async (portalId) => {
  const url = `${config.API_BASE_URL}/portals/${portalId}/organisation`;
  const response = await request({
    method: "get",
    url,
  });
  return response;
};

export const getOrgInfo = async (portalId) => {
  const url = `${config.API_BASE_URL}/portals/${portalId}`;
  const response = await request({
    method: "get",
    url,
  });
  return response;
};

export const getApplications = async (portalId, page=0, size=10) => {
  const url = `${config.API_BASE_URL}/portals/${portalId}/applications?page=${page}&size=${size}&active=true`;
  const response = await request({
    method: "get",
    url,
  });
  if (response.success) {
    const {
      raw: { data },
    } = response;
    
    const filterdData = getFilteredData(data);
    return { applications: {"applications": filterdData, "total": response.raw.resultTotal} };
  } else {
    throw new Error("Unable to fetch applications");
  }
};

export const getFilteredData = (data) => {
  return data.map((application) => ({
    ...application,
    courses: application.courses.filter(
      (c) => c?.course?.status === "active"
    ),
  }));
}

export const getOrgAdminLevels = async (portalId) => {
  const adminLevelsResponse = await request({
    method: "get",
    url: `${config.API_BASE_URL}/portals/${portalId}/administration-levels`,
  });
  const adminLevelItemsResponse = await request({
    method: "get",
    url: `${config.API_BASE_URL}/portals/${portalId}/administration-level-items`,
  });

  if (adminLevelsResponse.success && adminLevelItemsResponse.success) {
    const { raw: adminLevels } = adminLevelsResponse;
    const { raw: adminLevelItems } = adminLevelItemsResponse;
    const groupedItems = groupBy(adminLevelItems, "administrationLevel.id");
    return {
      adminLevels: adminLevels
        .map((al, i) => ({
          ...al,
          options: groupedItems[al.id]?.map((s) => ({
            ...s,
            label: s?.name,
            value: s?.id,
          })),
        }))
        .sort((a, b) => (a.levelOrder > b.levelOrder ? 1 : -1)),
      indexedAdminLevel: convertArrayToObject(adminLevels, 'id'),
      indexedAdminLevelItems: convertArrayToObject(adminLevelItems, 'id'),
      allAdminLevelItems: adminLevelItems,
    };
  } else {
    throw new Error(`Unable to fetch ${HIERARCHICAL_GROUP}s`);
  }
};

export const getOrgApplicationGroups = async (portalId) => {
  const url = `${config.API_BASE_URL}/portals/${portalId}/application-groups`;
  const response = await request({
    method: "get",
    url,
  });
  if (response.success) {
    const { raw: data } = response;
    return { applicationGroups: data };
  } else {
    throw new Error("Unable to fetch Application Groups");
  }
};

export const getOrgApplicationGroupItems = async (portalId) => {
  const url = `${config.API_BASE_URL}/portals/${portalId}/application-group-items`;
  const response = await request({
    method: "get",
    url,
  });
  if (response.success) {
    const { raw: data } = response;
    return { applicationGroupItems: data };
  } else {
    throw new Error("Unable to fetch Application Groups Items");
  }
};

export const formatCourses = (list, filters = {}) => {
  const courses = list
    .map((d) =>
      d.courses
        .filter((c) => c.course.status === "active")
        .map((c) => {
          return {
            ...c,
            ...c.course,
            timelines: d.timelines,
            applicationTitle: d.title,
            applicationPaymentItems: d.paymentItems,
            parentsIds: [
              c.course.administrationLevelItem.id,
              c.course.administrationLevelItem.parentId,
              ...(c.parents
                ?.filter((f) => f.administrationLevelItem)
                .map((f) => f.administrationLevelItem.id) || []),
            ],
            applicationInfo: {
              ...d,
              courses: undefined,
              tags: d.applicationGroupItems
                .flat()
                .map(({ name }) => ({ label: name })),
              tagIds: d.applicationGroupItems.flat().map(({ id }) => id),
            },
          };
        })
    )
    .flat()
    .filter((c) =>
      String(c.title)
        .toLocaleLowerCase()
        .includes(String(filters.searchPattern || "").toLocaleLowerCase())
    );

  const fCourses = courses.map((g) => ({
    ...g,
    applications: [g.applicationInfo],
    applicationInfo: undefined,
  }));

  return fCourses;
};

export const fetchCourses = async (portalId, filters = {}) => {
  const filterQueries = new URLSearchParams(filters).toString();
  const url = `${config.API_BASE_URL}/portals/${portalId}/applications?active=true&${filterQueries}&pageSize=10&pageNumber=0`;
  const response = await request({
    method: "get",
    url,
  });
  if (response.success) {
    const {
      raw: { data = [] },
    } = response;
    return formatCourses(data);
  } else {
    throw new Error("Unable to fetch courses");
  }
};

export const appData = async (orgName) => {
  try {
    const { raw: org } = await getOrg(orgName);
    // check if org exists
    if (!org.name) throw new Error(`organisation ${orgName} does not exist`);
    const { raw: portalSettings } = await getOrgInfo(orgName);
    const [
      applications,
      adminLevels,
      applicationGroups,
      applicationGroupItems,
      courses,
    ] = await Promise.all([
      getApplications(org.id),
      getOrgAdminLevels(org.id),
      getOrgApplicationGroups(org.id),
      getOrgApplicationGroupItems(org.id),
      fetchCourses(org.id, { searchPattern: "" }),
    ]);

    const coursesDuration = Array.from(
      new Set(courses.map((c) => `${c.duration} ${c.durationUnit}`))
    ).sort();
    const groupedItems = groupBy(
      applicationGroupItems.applicationGroupItems,
      "group.id"
    );
    const agItems =
      applicationGroups.applicationGroups?.map((item) => ({
        ...item,
        options: groupedItems[item.id],
      })) || [];
    return {
      ...org,
      portalSettings: {
        ...portalSettings,
        bannerUrl: portalSettings?.bannerUrl ? `${portalSettings?.bannerUrl}?${new Date().getTime()}` : ''
      },
      ...applications,
      ...adminLevels,
      applicationGroups: agItems,
      ...applicationGroupItems,
      courses,
      coursesDuration,
    };
  } catch (error) {
    throw new Error(
      error?.message ||
        error?.data ||
        error?.response?.data ||
        "Unable to fetch application data"
    );
  }
};
