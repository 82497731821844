import { createSlice } from "@reduxjs/toolkit";
import { Notify } from "@flexisaf/flexibull2/build/notify";
import { set } from "idb-keyval";
import * as api from "utils/api";
import config from "utils/config";
import request from "utils/request";

export const applicationSlice = createSlice({
  name: "applications",
  initialState: {
    applications: [],
    userLocale: "",
    total: 0,
    filteringApps: false,
    filters: {
      adminLevelFilters: null,
      appGroupFilters: null,
      dateRange: null,
    },
    searchValue: "",
    portalConfig: {
      courseAlias: "course",
    },
    isFetchingOrgData: false,
    error: null,
    courses: [],
    filteredCourses: [],
    openCourseModal: false,
    openedCourse: null,
    org: null,
    authModal: false,
    adminLevels: [],
    indexedAdminLevel: null,
    adminLevelItems: {
      indexedAdminLevelItems: null,
      allAdminLevelItems: [],
    },
    appGroups: [],
    appGroupItems: [],
    coursesDuration: [],
    courseDetails: null,
    isFetchingCourseDetails: false,
    isFetchingApplicationDetails: false,
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
    imgCacheClearer: new Date().getTime(),
  },

  reducers: {
    setFiltersData: (state, { payload }) => {
      state.filters = payload.filtersData;
      state.searchValue = payload.search;
    },
    filterAppsAndCoursesStart: (state, { payload }) => {
      state.filteringApps = true;
    },
    filterAppsAndCoursesSuccess: (state, { payload }) => {
      state.filteringApps = false;
    },
    filterAppsAndCoursesFailed: (state, { payload }) => {
      state.filteringApps = false;
    },
    fetchApplicationDetailsStart: (state, { payload }) => {
      state.isFetchingApplicationDetails = true;
    },
    fetchApplicationDetailsSuccess: (state, { payload }) => {
      state.isFetchingApplicationDetails = false;
    },
    fetchApplicationDetailsFailed: (state, { payload }) => {
      state.isFetchingApplicationDetails = false;
    },
    fetchCourseDetailsStart: (state, { payload }) => {
      state.isFetchingCourseDetails = true;
    },
    fetchCourseDetailsSuccess: (state, { payload }) => {
      state.isFetchingCourseDetails = false;
      state.courseDetails = payload;
    },
    fetchCourseDetailsFailed: (state, { payload }) => {
      state.isFetchingCourseDetails = false;
    },
    initializeServiceWorker: (state, { payload }) => {
      state.serviceWorkerInitialized = !state.serviceWorkerInitialized;
    },
    updateServiceWorker: (state, { payload }) => {
      state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
      state.serviceWorkerRegistration = payload;
    },
    setApplications: (state, { payload }) => {
      state.applications = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setCourses: (state, { payload }) => {
      state.courses = payload;
      state.filteredCourses = payload;
    },
    setOrg: (state, { payload }) => {
      state.org = payload;
    },
    setUserLocal: (state, { payload }) => {
      state.userLocale = payload;
    },
    setOrgAliases: (state, { payload }) => {
      state.portalConfig = payload;
    },
    setAdminLevels: (state, { payload }) => {
      state.adminLevels = payload.adminLevels;
      state.indexedAdminLevel = payload.indexedAdminLevel;
      state.adminLevelItems = {
        indexedAdminLevelItems: payload.indexedAdminLevelItems,
        allAdminLevelItems: payload.allAdminLevelItems,
      };
    },
    setAppGroups: (state, { payload }) => {
      state.appGroups = payload;
    },
    setAppGroupItems: (state, { payload }) => {
      state.appGroupItems = payload;
    },
    setCoursesDurations: (state, { payload }) => {
      state.coursesDuration = payload;
    },
    openCourse: (state, { payload }) => {
      state.openedCourse = payload;
      state.openCourseModal = true;
    },
    closeCourse: (state) => {
      state.openedCourse = null;
      state.openCourseModal = false;
    },
    setOrgDataStart: (state) => {
      state.isFetchingOrgData = true;
      state.error = null;
    },
    setOrgDataSuccess: (state) => {
      state.isFetchingOrgData = false;
      state.error = null;
    },
    setOrgDataFail: (state, { payload }) => {
      state.isFetchingOrgData = false;
      state.error = payload;
    },
    searchCourse: (state, { payload }) => {},
  },
});

export const {
  setFiltersData,
  filterAppsAndCoursesStart,
  filterAppsAndCoursesSuccess,
  filterAppsAndCoursesFailed,
  fetchApplicationDetailsStart,
  fetchApplicationDetailsSuccess,
  fetchApplicationDetailsFailed,
  fetchCourseDetailsStart,
  fetchCourseDetailsSuccess,
  fetchCourseDetailsFailed,
  setApplications,
  setTotal,
  setCourses,
  setOrg,
  setUserLocal,
  setOrgAliases,
  openCourse,
  closeCourse,
  setOrgDataSuccess,
  setOrgDataFail,
  setOrgDataStart,
  searchCourse,
  setAdminLevels,
  setAppGroups,
  setAppGroupItems,
  setCoursesDurations,
  initializeServiceWorker,
  updateServiceWorker,
} = applicationSlice.actions;

export const fetchOrgData = (orgName) => async (dispatch) => {
  try {
    dispatch(setOrgDataStart());
    const appData = await api.appData(orgName);
    await set("orgData", appData);
    const {
      portalSettings,
      name,
      id,
      logoUrl,
      applicationGroups,
      applicationGroupItems,
      adminLevels,
      indexedAdminLevel,
      indexedAdminLevelItems,
      allAdminLevelItems,
      courses,
      coursesDuration,
      applications,
      address,
      timeZone,
    } = appData;
    const org = { name, id, logoUrl, address, timeZone, ...portalSettings };
    dispatch(setApplications(applications.applications));
    dispatch(setTotal(applications.total));
    dispatch(setOrg(org));
    dispatch(setUserLocal(timeZone));
    dispatch(setOrgAliases({ courseAlias: appData?.courseAlias || 'Course' }));
    dispatch(
      setAdminLevels({
        adminLevels,
        indexedAdminLevelItems,
        allAdminLevelItems,
        indexedAdminLevel,
      })
    );
    dispatch(setAppGroups(applicationGroups));
    dispatch(setAppGroupItems(applicationGroupItems));
    dispatch(setCoursesDurations(coursesDuration));
    dispatch(setCourses(courses));
    dispatch(setOrgDataSuccess());
  } catch (error) {
    dispatch(setOrgDataFail(error));
  }
};

export const setOrgData = (appData) => (dispatch) => {
  const {
    portalSettings,
    name,
    id,
    logoUrl,
    applicationGroups,
    applicationGroupItems,
    adminLevels,
    indexedAdminLevel,
    indexedAdminLevelItems,
    allAdminLevelItems,
    courses,
    coursesDuration,
    applications,
    address,
    timeZone,
  } = appData;
  const org = { name, id, logoUrl, address, timeZone, ...portalSettings };
  dispatch(setApplications(applications.applications));
  dispatch(setTotal(applications.total));
  dispatch(setOrg(org));
  dispatch(setUserLocal(timeZone));
  dispatch(setOrgAliases({ courseAlias: appData?.courseAlias }));
  dispatch(
    setAdminLevels({
      adminLevels,
      indexedAdminLevelItems,
      allAdminLevelItems,
      indexedAdminLevel,
    })
  );
  dispatch(setAppGroups(applicationGroups));
  dispatch(setAppGroupItems(applicationGroupItems));
  dispatch(setCoursesDurations(coursesDuration));
  dispatch(setCourses(courses));
  dispatch(setOrgDataSuccess());
};

export const fetchCourseDetails =
  ({ id }, portalId, cb) =>
  async (dispatch) => {
    await dispatch(fetchCourseDetailsStart());
    const url = `${config.API_BASE_URL}/portals/${portalId}/courses/${id}`;
    const response = await request({
      method: "get",
      url,
    });
    if (response.success) {
      const { raw } = response;
      cb();
      await dispatch(fetchCourseDetailsSuccess(raw));
    } else {
      Notify("Failed to load course details", {
        position: "top-right",
        status: "error",
      });
      dispatch(fetchCourseDetailsFailed());
    }
  };

export const fetchApplicationDetails = (applicationId) => async (dispatch) => {
  const url = `${config.API_BASE_URL}/applications/${applicationId}`;
  const response = await request({
    method: "get",
    url,
  });
  if (response.success) {
    const { raw } = response;
    await dispatch(fetchApplicationDetailsSuccess());
    return {
      success: true,
      raw,
    };
  } else {
    await dispatch(fetchApplicationDetailsFailed());
    return {
      success: false,
    };
  }
};

export const fetchPaymentSummary = async (data) => {
  const url = `${config.API_BASE_URL}/checkout/summary`;
  return request({
    method: "post",
    url,
    data,
  });
};

export const getApplications = async (url) => {
  const response = await request({
    method: "get",
    url,
  });
  if (response.success) {
    const {
      raw: { data },
    } = response;

    const filterdData = api.getFilteredData(data);
    return { applications: filterdData };
  } else {
    throw new Error("Unable to fetch applications");
  }
};

export const loadMoreApplications =
  (portalId, apps, total, callBack) => async (dispatch) => {
    let page = Math.floor(apps.length / 10) * 10;
    let size = total - apps.length >= 10 ? 10 : total - apps.length;
    const url = `${config.API_BASE_URL}/portals/${portalId}/applications?page=${page}&size=${size}&active=true`;

    const [applications] = await Promise.all([getApplications(url)]);

    callBack(applications);

    dispatch(setApplications(apps.concat(applications.applications)));
  };

const fetchCourses = async (url) => {
  const response = await request({
    method: "get",
    url,
  });
  if (response.success) {
    const {
      raw: { data = [] },
    } = response;
    return api.formatCourses(data);
  } else {
    throw new Error("Unable to fetch courses");
  }
};

export const filterApplications = (data, orgId, layout) => async (dispatch) => {
  try {
    await dispatch(filterAppsAndCoursesStart());
    const {
      searchQuery,
      filters: {
        adminLevelFilters = null,
        appGroupFilters = [],
        dateRange = null,
      },
    } = data;
    const startDate = dateRange ? dateRange[0] : null;
    const endDate = dateRange ? dateRange[1] : null;
    const groupItemIds =
      appGroupFilters &&
      appGroupFilters
        .map((groupId) => `&groupItemId=${groupId}`)
        .toString()
        .replace(/,/g, "");
    const url = `${
      config.API_BASE_URL
    }/portals/${orgId}/applications?active=true&pageSize=10&pageNumber=0
              ${layout === "GRID_VIEW" ? `&keyword=${searchQuery}` : ""}${
      adminLevelFilters ? `&adminLevelItemId=${adminLevelFilters}` : ""
    }${appGroupFilters ? `${groupItemIds}` : ""}${
      dateRange ? `&startDate=${startDate}&endDate=${endDate}` : ""
    }`;
    const [applications, courses] = await Promise.all([
      getApplications(url, orgId),
      fetchCourses(url, orgId),
    ]);
    dispatch(setApplications(applications.applications));
    dispatch(setCourses(courses));
    await dispatch(filterAppsAndCoursesSuccess());
  } catch (error) {
    await dispatch(filterAppsAndCoursesFailed());
    Notify("Something went wrong please try again", {
      position: "top-right",
      status: "error",
    });
  }
};

export const setFilters = (search, filtersData) => (dispatch) => {
  dispatch(setFiltersData({ filtersData, search }));
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectApplications = (state) => state.applications;

export default applicationSlice.reducer;
