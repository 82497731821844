import React from "react";
import { Text, Box, Grid, Spacer, Input } from "@flexisaf/flexibull2";
import Footer from "./NecoVerificationFooter";
import { useSelector, useDispatch } from "react-redux";
import { selectNeco, handleChange } from "redux/neco/necoSlice";
import { Theme } from "utils/theme";

const NecoVerificationCandidateInfo = () => {
  const { allCandidateInfo, allExams } = useSelector(selectNeco);
  const dispatch = useDispatch();
  return (
    <Box>
      <Box
        pad="20px 50px"
        width="50vw"
        margin="auto"
        style={{ textAlign: "left" }}
      >
        <Box margin="20px 0 0">
          <Grid default="repeat(3, 1fr)" md="repeat(3, 1fr)" sm="repeat(2, 1fr)">
            <Box margin="0 0 24px">
              <Text bold Block>
                First Name
              </Text>
              <Spacer space="12px" />
              <Text Block>{allCandidateInfo[0]?.firstName}</Text>
            </Box>
            <Box>
              <Text bold Block>
                Middle Name
              </Text>
              <Spacer space="12px" />
              <Text Block>{allCandidateInfo[0]?.middleName}</Text>
            </Box>
            <Box>
              <Text bold Block>
                Last Name
              </Text>
              <Spacer space="12px" />
              <Text Block>{allCandidateInfo[0]?.lastName}</Text>
            </Box>
          </Grid>
          <Grid default="repeat(3, 1fr)">
            <Box>
              <Text bold Block>
                Date of birth
              </Text>
              <Spacer space="12px" />
              <Text Block>{allCandidateInfo[0]?.dob}</Text>
            </Box>
            <Box>
              <Text bold Block>
                Examination Number
              </Text>
              <Spacer space="12px" />
              <Text Block>{allExams[0]?.examNo}</Text>
            </Box>
            <Box>
              <Text bold Block>
                Exam year
              </Text>
              <Spacer space="12px" />
              <Text Block>{allExams[0]?.examYear}</Text>
            </Box>
          </Grid>
          <Spacer space="50px" />
          <Grid default="1fr">
            <Input
              name="token"
              label="Token"
              placeholder="enter token"
              required
              width="200px"
              onChange={(event) =>
                dispatch(
                  handleChange({
                    index: 0,
                    name: event.target.name,
                    value: event.target.value,
                  })
                )
              }
              value={allExams[0]?.token}
            />
            <Spacer space="20px" />
            <Text
              style={{ cursor: "pointer" }}
              bold
              onClick={() =>
                window.open("https://result.neco.gov.ng/login", "_blank")
              }
              color={Theme.PrimaryColor}
            >
              Dont have a token yet? Tap here to get one
            </Text>
          </Grid>
        </Box>
      </Box>
      <Footer prev={2} next={4} />
    </Box>
  );
};

export default NecoVerificationCandidateInfo;
