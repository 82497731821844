import React, { useEffect } from "react";
import {
  Modal,
  ModalBody,
  Box,
  Button,
  Grid,
} from "@flexisaf/flexibull2";

const SWUpdateAlert = ({ open, update, updatingSW, cancel }) => {
  useEffect(() => {
    if (!update) {
      const timer = setTimeout(() => {
        update();
      }, 6000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, []);

  return (
      <Modal onClose={cancel} open={open}>
        <ModalBody
          bgColor="#fff"
          width="350px"
          style={{
            boxShadow: "#4e399212 -4px 0px 20px 10px",
          }}
        >
          <Box pad="20px 30px 0 30px">
            <h2>New Update Available</h2>
          </Box>
          <Box pad="0 30px 30px 30px">
            <Box margin="20px 0 30px">
              <p>
                The application has been updated and you need to reload the page
                to continue
              </p>
              <p>
                Make sure all unsaved changes are copied before reloading; they will be
                lost once you reload the page
              </p>
            </Box>
            
            <Grid default="1fr 1fr" margin="0 0 0 auto">
              <Button height={50} pale color="transparent" fontColor="#cc" onClick={cancel}>
                Cancel
              </Button>
              <Button progress={updatingSW} height={45} onClick={update}>Update app</Button>
            </Grid>
          </Box>
        </ModalBody>
      </Modal>
  );
};

export default SWUpdateAlert;
