import styled from "styled-components";
import { mix } from "polished";

export const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => mix(0.9, "#000", theme.PrimaryColor)};
  position: relative;
  padding-top: 10rem;
  padding-bottom: 2rem;
  margin-top: auto;

  &::after {
    content: "";
    width: 4.4rem;
    height: 7.6rem;
    position: absolute;
    left: calc(50% - 7.3rem);
    top: -3.8rem;
    background: url(/images/dot_pattern.png) no-repeat 0 0;
  }

  & .footer-text {
    display: flex;
    width: 100%;
    margin-top: 6rem;
    padding-bottom: 1rem;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.7);

    p {
      &:first-child {
        padding-top: 2rem;
      }
    }

    @media (max-width: 500px) {
      padding: 2rem;
      flex-direction: column;
      text-align: center;
    }

    & .copyright {
      padding-top: 2rem;
    }
  }

  & .footer-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem;

    @media (max-width: 500px) {
      grid-template-columns: 100%;
      width: fit-content;
      display: grid;
      text-align: left;
      grid-gap: 4rem;
    }

    & h3 {
      color: #fff;
      font-size: 3.2rem;
      font-weight: 500;

      & span {
        color: ${({ theme }) => theme.PrimaryColor};
      }
    }

    & .reg-button {
      @media (min-width: 500px) {
        margin-left: auto;
        margin-right: 0;
      }
      @media (max-width: 500px) {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }

  & .contact-section {
    color: #ffffffb3;

    .address-icon {
      color: ${({ theme }) => theme.PrimaryColor};
      margin-right: 8px;
      font-size: 14px;
    }

    & ol.contact-cover {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      list-style: none;
      counter-reset: list-counter;
      grid-gap: 20px;
      
      @media (max-width: 850px) {
        grid-template-columns: 1fr 1fr;
      }
      
      @media (max-width: 500px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
      }

      li {
        counter-increment: list-counter;
        display: flex;
        align-items: center;

        & .icon {
          color: ${({ theme }) => theme.PrimaryColor};
          margin-right: 10px;
        }

        &::before {
          content: counter(list-counter);
          border: 1px solid
            ${({ theme }) => mix(0.6, "#000", theme.PrimaryColor)};
          font-size: 16px;
          padding: 21px;
          border-radius: 10px;
          line-height: 2rem;
          color: ${({ theme }) => theme.PrimaryColor};
          text-align: center;
          margin-right: 1.3rem;
        }
      }
    }
  }
`;
