const PrimaryTheme = '#825EFB';

export const Theme = {
  PrimaryColor: PrimaryTheme,
  PrimaryGrey: '#62707B',

  PrimaryBackground: '#F8F8F5',
  PrimaryGreen: '#59d973',
  PrimaryMint: '#95fd35',
  PrimaryRed: '#ea2a8a',
  PrimaryYellow: '#F2C02E',
  PrimaryOrange: '#EF7E23',
  PrimaryBlue: '#5BB1F2',
  SecondaryColor: '#be7efc',
  TetiaryColor: '#ecdbfe',

  PrimaryLineColor: '#C8C5D0',

  PrimaryBorderColor: '#D8D8E3',
  PrimaryFade: '#F5F5F7',
  PrimaryInputOutline: '#9D9DB7',
  PrimaryFont: "'Inter', sans-serif",
  PrimaryShadow: '0px 2px 10px 0px #1C1A461A',

  SideNavOpen: '240px',
  SideNavClose: '80px'
};
