import React from "react";
import {
  Grid,
  Box,
  Layout,
  Tag,
  Spacer,
  Text,
  Button,
} from "@flexisaf/flexibull2";
import { Theme } from "utils/theme";
import TagList from "components/Tag";
import { useSelector } from "react-redux";
import { selectApplications } from "redux/landing/landingSlice";
import { FormWrapper, StatusProps } from "./style";
import { getColumnStructure } from "./utils";
import { formatAddress } from "utils/helpers";

const moment = require('moment-timezone');

const PrintPreview = () => {
  const printData = JSON.parse(localStorage.getItem("printData"));
  const { org, portalConfig, userLocale } = useSelector(selectApplications);

  if (!printData) {
    return null;
  }

  const { data, formItem, formItemData, printSummary } = printData;

  return (
    <Layout
      theme={{
        PrimaryColor: org?.themeColor ? org.themeColor : Theme.PrimaryColor,
      }}
    >
      <FormWrapper>
        <Box pad="30px 40px" background="#fff">
          <Box margin="10px auto 30px" align="center" vAlign>
            <Text bold block size="25px" color={Theme.PrimaryColor}>
              {printSummary ? "Acknowledgement" : "Review Form"}
            </Text>
          </Box>
          <Grid default="1fr max-content" gap="20px" templateRows="1fr auto">
            <Grid default="auto max-content" gap="20px">
              <Box>
                <Text bold block size="20px">
                  {data?.name}
                </Text>
                <Spacer space="10px" />
                {data?.regDate && (
                  <Text block>Registered Date: {data?.regDate}</Text>
                )}
                <Text block>Application Number: {data?.applicationNumber}</Text>
                <Spacer space="10px" />
                {StatusProps(data?.status) && (
                  <Tag
                    style={{ borderWidth: "2px" }}
                    color={StatusProps(data?.status).color}
                  >
                    {StatusProps(data?.status).label}
                  </Tag>
                )}
              </Box>
            </Grid>
            <Box margin="0 0 0 auto" align="right">
              <Button
                width={160}
                iconRight={<i className="safapply-print"></i>}
                round
                bold
                onClick={() => window.print()}
                className="print-form"
              >
                Print Form
              </Button>
            </Box>
            <Box style={{ gridColumn: "1/3" }}>
              <Spacer space="5px" />
              <Box margin="0 40px 0 0">
                <Text color={Theme.PrimaryColor} bold block capitalize>
                  {data?.applicationTitle}
                </Text>
              </Box>
              <Box margin="10px 40px 0 0">
                <Text bold uppercase>
                  {portalConfig?.courseAlias}:
                </Text>{" "}
                <Text uppercase>
                  {data?.course?.title} - {data?.course?.code}
                </Text>
              </Box>
              <Box margin="10px 0 0 0">
                <TagList
                  lists={data?.tags || []}
                  tagProps={{
                    pad: "4px 12px",
                    size: "12px",
                    margin: "4px 10px 8px 0px",
                    height: "25px",
                    weight: "normal",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Box>
        <Box pad="0 10px 10px 40px" className="view-application-box">
          <>
            <Box margin=" 0 30px 0 0">
              <Grid default="1fr 260px">
                <Box />
                <Box
                  background="#fff"
                  margin="auto 0 -15.8rem auto"
                  pad="8px"
                  style={{ border: "1px solid #D8D8E3", zIndex: 2 }}
                >
                  {formItemData?.passport_picture && (
                    <img
                      width="250px"
                      src={formItemData?.passport_picture}
                      alt="Passport"
                    />
                  )}
                </Box>
              </Grid>
            </Box>
            <Box className="applicant-info">
              {formItem.map((item, i) => (
                <Box margin="40px 0 0" pad="0" key={i}>
                  <Text block bold capitalize size="16px" uppercase>
                    {item.title}
                  </Text>
                  <Box
                    margin="10px 0 0"
                    width="50px"
                    background={Theme.PrimaryBorderColor}
                    height="1px"
                  />
                  <Spacer space="20px" />
                  {item.inputs.map((eachRow, rowIndex) => (
                    <Grid
                      className="form-grid"
                      default={getColumnStructure(eachRow)}
                      gap="0"
                      key={`row-${rowIndex}`}
                    >
                      {eachRow.map((eachField, i) => (
                        <Box
                          key={i}
                          pad="20px 30px 20px 0"
                          className="single-form"
                        >
                          <Text uppercase size="13px" bold block>
                            {eachField.title}
                          </Text>
                          <Spacer space="15px" />
                          {eachField?.source === "nigeria-states-lga" ? (
                            <Text capitalize size="15px" block>
                              {formatAddress(formItemData?.[eachField.id])}
                            </Text>
                          ) : (
                            <Text
                              capitalize={
                                !["email", "wysiwyg"].includes(
                                  eachField.type?.toLowerCase()
                                )
                              }
                              block
                            >
                              {
                                eachField?.type === 'checkbox' ? <>{formItemData?.[eachField.id] ? eachField.trueDisplay : eachField.falseDisplay }</> : (
                                  <>
                                    {['date', 'single-date'].includes(eachField.type?.toLowerCase()) &&
                                    formItemData?.[eachField.id] ? (
                                      moment(formItemData?.[eachField.id]).tz(userLocale || 'Africa/Lagos').format(
                                        "DD-MM-YYYY"
                                      )
                                    ) : (
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: formItemData?.[eachField.id],
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              }
                            </Text>
                          )}
                        </Box>
                      ))}
                    </Grid>
                  ))}
                </Box>
              ))}
            </Box>
          </>
        </Box>
        <Box margin="20px 30px 70px" align="right" className="form-actions">
          <Button
            height={50}
            width={160}
            pale
            spaceRight="40px"
            iconLeft={<i className="safapply-left-1"></i>}
            onClick={() => window.close()}
          >
            Close
          </Button>
        </Box>
      </FormWrapper>
    </Layout>
  );
};

export default PrintPreview;
