import Routes from "routes";
import { ErrorFallBack } from "components/ErrorFallBack";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
// import { useQuery } from "react-query";
// import { appData } from "utils/api";

import {
  // setOrgData,
  fetchOrgData,
  selectApplications,
  updateServiceWorker,
} from "redux/landing/landingSlice";
import { Layout, Loader, Box } from "@flexisaf/flexibull2";
import React, { Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import "assets/icons/fontello/css/safapply-applicant.css";
import "assets/icons/fontello/css/animation.css";
import "react-loading-skeleton/dist/skeleton.css";
import { BlankPage } from "components/OrgSelection/style";
import { OrgSelection } from "components/OrgSelection";
import SWUpdateAlert from "components/SWUpdateAlert";
import config from "utils/config";
import { selectAuthUser } from "redux/auth/authSlice";

ReactGA.initialize(config.GA_MEASUREMENT_ID);

const App = () => {
  const { params } = useRouteMatch();
  const dispatch = useDispatch();
  // const { isLoading, data, error } = useQuery(
  //   ["orgData", params.orgName],
  //   () => appData(params.orgName),
  //   {
  //     // Refetch the data every 5 minutes
  //     refetchInterval: 300000,
  //   }
  // );
  useEffect(() => {
    dispatch(fetchOrgData(params.orgName));
  }, [dispatch, params.orgName]);
  const { org = {}, isFetchingOrgData, error } = useSelector(selectApplications);
  const user = useSelector(selectAuthUser);

  useEffect(() => {
    TagManager.initialize({ gtmId: config.GTM_ID });
  }, []);

  useEffect(() => {
    if (user?.id && window?.dataLayer) {
      window.dataLayer.push({
        event: "user_session",
        orgId: params.orgName,
        userId: user.id,
      });
    }
  }, [user, params.orgName]);

  useEffect(() => {
    if (params?.orgName && org?.name) {
      ReactGA.event({
        category: "org name",
        action: params.orgName,
        label: org.name,
      });
    }
  }, [params.orgName, org]);

  if (isFetchingOrgData)
    return (
      <BlankPage>
        <Box margin="50vh auto">
          <Loader
            color={org?.themeColor ? org.themeColor : "grey"}
            section
            size={65}
          />
        </Box>
      </BlankPage>
    );
  if (error) {
    return (
      <BlankPage>
        <OrgSelection title="Organisation could not be found" />
      </BlankPage>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallBack}>
      <Suspense
        fallback={
          <Loader
            size={60}
            color={org?.themeColor ? org.themeColor : "grey"}
            fullscreen={true}
          />
        }
      >
        <Routes />
      </Suspense>
    </ErrorBoundary>
  );
};

const SubApp = () => {
  const dispatch = useDispatch();
  const [updatingSW, setUpdatingSW] = useState(false);

  const { serviceWorkerUpdated, serviceWorkerRegistration } =
    useSelector(selectApplications);

  const closeUpdate = () => {
    if (updatingSW) return;
    dispatch(updateServiceWorker(false));
  };

  const updateServiceApp = () => {
    const registrationWaiting = serviceWorkerRegistration?.waiting;

    if (registrationWaiting) {
      setUpdatingSW(true);
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          closeUpdate();
          window.location.reload(true);
        }
      });
      window.location.reload(true);
    } else {
      closeUpdate();
    }
  };

  return (
    <Layout>
      {serviceWorkerUpdated && (
        <SWUpdateAlert
          open={serviceWorkerUpdated}
          update={updateServiceApp}
          cancel={closeUpdate}
          updatingSW={updatingSW}
        />
      )}
      <Router>
        <Switch>
          <Route path="/:orgName/" component={App} />
          <Route
            component={() => (
              <BlankPage>
                <OrgSelection title="Organisation not Selected" />
              </BlankPage>
            )}
          />
        </Switch>
      </Router>
    </Layout>
  );
};

export default SubApp;
