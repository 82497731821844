import React from 'react';
import styled, { css } from 'styled-components';
import { stringToHslColor } from 'utils/stringToHSLColor';

const TagWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  height: 1.4rem;
  border-radius: 1.2rem;
  font-size: 1rem;
  padding: 0.7rem 0.8rem 0.7rem;
  font-weight: 600;
  margin-left: 0;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  background-color: ${({ children }) => stringToHslColor(children, 40, 84)};
  color: ${({ children }) => stringToHslColor(children, 20, 20)};
  ${(props) =>
    props &&
    css`
      width: ${(props) => props.width};
      font-size: ${(props) => props.size};
      height: ${(props) => props.height};
      font-weight: ${(props) => props.weight};
      padding: ${(props) => props.pad};
      margin: ${(props) => props.margin};
    `}
`;

const Tag = (props) => {
  return <TagWrapper {...props} >{props.label}</TagWrapper>;
};

export default Tag;
