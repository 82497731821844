import React, { useEffect, useState } from "react";
import { Text, Box, Grid, Spacer, Button } from "@flexisaf/flexibull2";
import { darken } from "polished";
import { Layout } from "components/Layout";

import { PaymentNotice } from "./style";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import request from "utils/request";
import config from "utils/config";
import { selectApplications } from "redux/landing/landingSlice";
import {
  saveCurrentApplication,
  selectPersonalArea,
} from "redux/auth/personalAreaSlice";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "@flexisaf/flexibull2/build/loader";
import { Theme } from "utils/theme";
import { queryTransactionStatus } from "redux/auth/personalAreaSlice";

import SuccessImage from "../../assets/images/success.svg";
import FailedImage from "../../assets/images/failure.svg";
import PendingImage from "../../assets/images/pending.svg";
import ProcessingImage from "../../assets/images/processing.svg";

import NecoVerificationModal from "components/NecoVerification/NecoVerificationModal";
import {
  selectNeco,
  setCandidateId,
  setVerificationStep,
} from "redux/neco/necoSlice";
import { selectAuth } from "redux/auth/authSlice";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const PaymentFeedback = () => {
  const [status, setStatus] = useState(null);
  const [applicationInfo, setApplicationInfo] = useState(null);
  const [hasNecoEnabled, setHasNecoEnabled] = useState(null);
  const transactionRef = useQuery().get("orderID");
  const { org } = useSelector(selectApplications);
  const { user } = useSelector(selectAuth);
  const { isQueryingStatus } = useSelector(selectPersonalArea);
  const history = useHistory();
  const { params } = useRouteMatch();
  const dispatch = useDispatch();

  async function getApplicationInfo(applicationId) {
    const url = `${config.API_BASE_URL}/applications/${applicationId}`;
    const response = await request({
      method: "get",
      url,
    });
    if (response.success) {
      const { raw } = response;
      raw?.addonIds.includes("neco")
        ? setHasNecoEnabled(true)
        : setHasNecoEnabled(false);
      dispatch(saveCurrentApplication(raw));
    } else {
      return;
    }
  }

  const checkPayment = async () => {
    const url = `${config.API_BASE_URL}/payments/status?ref=${transactionRef}`;
    const response = await request({
      method: "get",
      url,
    });
    if (response.success) {
      const { raw } = response;
      setStatus(raw.status);
      setApplicationInfo(raw);
      dispatch(setCandidateId(raw?.candidateId));
      getApplicationInfo(raw?.applicationId);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (user?.id) {
      const payment = setTimeout(() => {
        checkPayment();
      }, 10000);

      return () => {
        clearTimeout(payment);
      };
    } else {
      setStatus("SUCCESS_LOGIN");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionRef]);

  const retryPayment = () => {
    window.location.replace(
      `https://paylog.flexisaf.com/#checkout;ref=${transactionRef}`
    );
  };

  const handlePaymentSuccess = () => {
    // window.dataLayer.push({
    //   event: 'payment',
    //   orgId: params.orgName,
    //   candidateId: applicationInfo?.candidateId,
    //   paymentType: applicationInfo?.paymentType,
    //   transactionRef: applicationInfo?.transactionRef,
    //   userId: user.id,
    //   amount: applicationInfo?.amount
    // });
    if (
      applicationInfo.paymentMode === "AFTER" ||
      applicationInfo.paymentType === "CONDITIONAL_OFFER"
    ) {
      return history.push(`/${params.orgName}/personal-area`);
    }
    dispatch(setVerificationStep(false));
    return history.push({
      pathname: `/${params.orgName}/application-form`,
      state: {
        application: applicationInfo?.applicationId,
        formId: applicationInfo.formId,
        course: applicationInfo.courseId,
        applicationInfo: applicationInfo,
        courseInfo: applicationInfo.course,
        candidateId: applicationInfo.candidateId,
      },
    });
  };

  return (
    <Layout>
      <PaymentNotice>
        <Box
          display="grid"
          width="100vw"
          height="100vh"
          style={{ placeItems: "center" }}
        >
          {!status ? (
            <Loader color={org?.themeColor || Theme.PrimaryColor} size={60} />
          ) : (
            <Box
              margin="100px auto 300px"
              maxWidth="400px"
              background="#fff"
              pad="50px 30px 20px"
              className="main-form"
            >
              {status === "SUCCESS_LOGIN" && (
                <SuccessLogin params={params} history={history} />
              )}
              {status === "SUCCESS" && (
                <Success
                  hasNecoEnabled={hasNecoEnabled}
                  paymentMode={applicationInfo?.paymentMode}
                  paymentType={applicationInfo?.paymentType}
                  onNext={handlePaymentSuccess}
                />
              )}
              {status === "PENDING" && (
                <Pending
                  transactionRef={transactionRef}
                  onNext={(status) => setStatus(status)}
                  loading={isQueryingStatus}
                />
              )}
              {status === "FAILED" && <Failed onNext={retryPayment} />}
              {status === "PROCESS" && <Processing onNext={retryPayment} />}
            </Box>
          )}
        </Box>
      </PaymentNotice>
    </Layout>
  );
};

const Success = ({ onNext, hasNecoEnabled, paymentMode, paymentType }) => {
  const dispatch = useDispatch();
  const { verificationStep } = useSelector(selectNeco);
  return (
    <Box>
      <Grid default="1fr" templateRow="1fr 1fr">
        <Box margin=" 0 0 10px 0">
          <img width="70px" src={SuccessImage} alt="successful transaction" />
          <Spacer space="10px" />
          <Text
            bold
            uppercase
            color={({ theme }) => darken(0.2, theme.PrimaryGreen || "#26a640")}
          >
            Transaction Successful
          </Text>
        </Box>
        <Box>
          <Text block>
            Cheers!! Your transaction was successful,{" "}
            {paymentMode === "BEFORE" && paymentType === "REGISTRATION"
              ? `Proceed with your application.`
              : `Proceed to the application dashboard to view your application status.`}
          </Text>
          <Spacer space="30px" />
          <Button
            width={120}
            color="#26a640"
            bold
            onClick={
              hasNecoEnabled &&
              paymentMode === "BEFORE" &&
              paymentType === "REGISTRATION"
                ? () => dispatch(setVerificationStep(true))
                : onNext
            }
          >
            Continue
          </Button>
        </Box>
      </Grid>
      {verificationStep && (
        <NecoVerificationModal
          necoVerificationStep={verificationStep}
          onClose={setVerificationStep}
          handlePaymentSuccess={onNext}
          applySuccess={onNext}
          hasPayment={true}
        />
      )}
    </Box>
  );
};

const SuccessLogin = ({ params, history }) => {
  return (
    <Box>
      <Grid default="1fr" templateRow="1fr 1fr">
        <Box margin=" 0 0 10px 0">
          <img width="70px" src={PendingImage} alt="successful transaction" />
          <Spacer space="10px" />
          <Text bold uppercase color={() => darken(0.2, "#d0650f")}>
            Transaction is being processed...
          </Text>
        </Box>
        <Box>
          <Text block>
            To view your payment history and details, please log back into your
            account using the email address associated with your submitted
            application.
          </Text>
          <Spacer space="30px" />
          <Button
            width={120}
            color="#d0650f"
            bold
            onClick={() => history.push(`/${params.orgName}/login`)}
          >
            Continue
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

const Failed = ({ onNext }) => {
  return (
    <Grid default="1fr" templateRow="1fr 1fr">
      <Box margin=" 0 0 10px 0">
        <img width="70px" src={FailedImage} alt="failed transaction" />
        <Spacer space="10px" />
        <Text
          bold
          uppercase
          color={({ theme }) => darken(0.1, theme.PrimaryRed || "#cd1471")}
        >
          Transaction Failed
        </Text>
      </Box>
      <Box>
        <Text block>
          Opps! Your transsaction failed and could not be completed, kindly
          confirm your card details and try again.
        </Text>
        <Spacer space="30px" />
        <Button width={120} color="#cd1471" bold onClick={onNext}>
          Retry
        </Button>
      </Box>
    </Grid>
  );
};

const Pending = ({ transactionRef, loading, onNext }) => {
  const dispatch = useDispatch();

  return (
    <Grid default="1fr" templateRow="1fr 1fr">
      <Box margin=" 0 0 10px 0">
        <img width="70px" src={PendingImage} alt="pending transaction" />
        <Spacer space="10px" />
        <Text
          bold
          uppercase
          color={({ theme }) => darken(0.1, theme.PrimaryOrange || "#d0650f")}
        >
          Transaction Pending
        </Text>
      </Box>
      <Box>
        <Text block>
          Your transsaction is pending and awaiting confirmation, kindly check
          status to find out the new status of your transaction.
        </Text>
        <Spacer space="30px" />
        <Button
          width={120}
          color="#d0650f"
          bold
          progress={loading}
          onClick={() =>
            dispatch(
              queryTransactionStatus(transactionRef, (data) => {
                onNext(data);
              })
            )
          }
        >
          Check Status
        </Button>
      </Box>
    </Grid>
  );
};

const Processing = ({ onNext }) => {
  return (
    <Grid default="1fr" templateRow="1fr 1fr">
      <Box margin=" 0 0 10px 0">
        <img width="70px" src={ProcessingImage} alt="processing transaction" />
        <Spacer space="10px" />
        <Text
          bold
          uppercase
          color={({ theme }) => darken(0.1, theme.PrimaryGrey || "#4b565f")}
        >
          Transaction is Processing
        </Text>
      </Box>
      <Box>
        <Text block>
          Your Transaction is still progressing, Waiting a few minutes to
          confirm the Status of your transaction.
        </Text>
        <Spacer space="30px" />
        <Button
          disabled
          progress
          width={120}
          color="#4b565f"
          bold
          onClick={onNext}
        >
          Processing...
        </Button>
      </Box>
    </Grid>
  );
};
