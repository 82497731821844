import dayjs from "dayjs";
import {
  clearLocalAccessToken,
  clearLocalRefreshToken,
  clearLocalUser,
} from "./helpers";

export const logout = () => {
  clearLocalAccessToken();
  clearLocalRefreshToken();
  clearLocalUser();

  window.location.reload();
};

export const getEndingDays = (endDate) => {
  const today = dayjs().format("YYYY-MM-DD");
  const deadline = dayjs(endDate);
  const remainingDays = deadline.diff(today, "day");

  return remainingDays < 1 ? 0 : remainingDays;
};

export const getStartingDays = (startDate) => {
  const today = dayjs();
  const startDay = dayjs(startDate);
  const remainingDays = today.diff(startDay, "day");

  return Math.abs(remainingDays);
};

export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export const isJson = (val) => {
  try {
    JSON.parse(val);
    return true;
  } catch (e) {
    return false;
  }
};

function formatTimeDateObject(time) {
  const newTime = time.length === 1 ? `0${time}` : time;
  return newTime;
}

function getCurrentDate() {
  var dateObj = new Date();
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();
  const newdate = `${year}-${formatTimeDateObject(month.toString())}-${formatTimeDateObject(day.toString())}`
  return newdate;
}

export const convertMS = (milliseconds) => {
  var hour, minute, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  hour = hour % 24;
  const time = `${formatTimeDateObject(hour.toString())}:${formatTimeDateObject(minute.toString())}:${formatTimeDateObject(seconds.toString())}`;
  const date = getCurrentDate();
  return `${date} ${time}`
};