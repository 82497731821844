import React from "react";
import Joyride from 'react-joyride';

import Header from "./Header";
import Banner from "./Banner";
import { Layout, Box } from "@flexisaf/flexibull2";
import { useSelector } from "react-redux";
import { selectApplications } from "redux/landing/landingSlice";
import { Wrapper, MainWrapper } from './layout.style';
import { Theme } from "utils/theme";

import usePersonAreaTour from "hooks/tours/usePersonalAreaTour";


const Main = ({children}) => {
  
  return (
    <MainWrapper>
      <Header />
      <Banner  />
      <div className="grid-container"> 
        {children}
      </div>
     
    </MainWrapper>
  );
};

export const ApplicantLayout = ({ children, theme }) => {
  const { org } = useSelector(selectApplications);
  const { tour: { run, steps }, handleCallback  } = usePersonAreaTour();

  return (
      <Layout style={{minWidth: "auto !important"}} theme={{ PrimaryColor: org?.themeColor ? org.themeColor : theme.PrimaryColor }}>
        
        <Wrapper id="personal-area">
          <Main>
              
            <Box>
              {children}
            </Box>
          </Main>
        </Wrapper>
        <Joyride 
          callback={handleCallback} 
          // stepIndex={stepIndex}
          steps={steps} 
          disableOverlay={false}
          style={{zIndex: 1000}}
          run={run} 
          showSkipButton 
          showProgress 
          hideCloseButton={false}
          // debug
          continuous
          // showNextButton 
          styles={{
            options: {
              primaryColor: org?.themeColor ? org.themeColor : Theme.PrimaryColor
            }
          }} 
        />
      </Layout>
  );
};

