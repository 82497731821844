import axios from "axios";
// import bugsnagErrorLogger from './bugsnagErrorHandler';
import urls from "./config";
import { getLocalAccessToken, getLocalRefreshToken, getLocalUser, setLocalAccessToken } from "./helpers";
import { logout } from "./index";

const fetch = (options) => {
  const { method = "get", data, url, responseType, headers } = options;

  const authToken = getLocalAccessToken();
  // eslint-disable-next-line no-unused-expressions
  authToken &&
    (axios.defaults.headers.common.Authorization = `Bearer ${authToken}`);

  switch (method.toLowerCase()) {
    case "get":
      return axios.get(url, {
        params: data,
        responseType,
        headers,
      });
    case "delete":
      return axios.delete(url, {
        data,
      });
    case "post":
      switch (options.upload) {
        case true:
          return axios.post(url, options.formData, options.config);
        default:
          return axios.post(url, data, { headers });
      }
    case "put":
      return axios.put(url, data, { headers });
    case "patch":
      return axios.patch(url, data, { headers });
    case "download":
      return axios.get(url, {
        params: data,
        responseType,
      });
    default:
      return axios(options);
  }
};

export default function request(options) {
  return fetch(options)
    .then((response) => {
      const { data, status } = response;
      return {
        success: true,
        raw: data,
        statusCode: status,
      };
    })
    .catch((err) => {
      const { response } = err;
      let msg;
      let statusCode;
      let returnedUserId;
      // const user = JSON.parse(getLocalUser()) || null;
      // bugsnagErrorLogger(err, user)
      if (response && response instanceof Object) {
        const { data, statusText } = response;
        statusCode = response.status;

        msg = data.error || statusText;
        returnedUserId = data.userId;

        if (statusCode === 401) {
          const refresh_token = getLocalRefreshToken();
          const userDetails = JSON.parse(getLocalUser());

          if (!userDetails) {
            return { success: false, statusCode, message: msg, returnedUserId };
          }

          const payload = {
            grant_type: "refresh_grant",
            refresh_token,
            client_secret: userDetails?.secret,
            client_id: userDetails?.id,
          };

          const encodeFormData = (data) =>
            Object.keys(data)
              .map(
                (key) =>
                  `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
              )
              .join("&");

          return axios
            .post(
              `${urls.UMS_BASE_URL}/oauth2/token`,
              encodeFormData(payload),
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: "Bearer",
                },
              }
            )
            .then((resp) => {
              const refreshData = resp.data ? resp.data : {};
              setLocalAccessToken(refreshData.access_token)
              return request(options);
            })
            .catch((error) => {
              const errResponse = error.response;
              const errStatus = errResponse.status;
              if (errStatus === 401 || errStatus === 404) {
                return logout();
              }
              return {
                success: false,
                statusCode: errStatus,
                message: errResponse.data
                  ? errResponse.data.message
                  : errResponse.statusText,
              };
            });
        }
      } else {
        statusCode = 600;
        msg = "Poor internet connections.";
      }
      return { success: false, statusCode, message: msg, returnedUserId };
    });
}
