import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { Modal, Box } from "@flexisaf/flexibull2";
import { Theme } from "utils/theme";

export const SingleModalWrapper = styled(Modal)`
  & .flexi-modal-overlay {
    opacity: 0.2;
  }

  & .flexi-modal-body {
    @media (max-width: 550px) {
      overflow-y: scroll !important;
      width: 97vw !important;
      padding-bottom: calc(75vh - 20px);
      height: 98vh;

      & .resizer {
        display: none;
      }

      & .name-section {
        grid-column: 1/3;
      }

      & .print-section {
        position: fixed;
        right: 34px;
        left: auto;
        background: #fff;
        bottom: 34px;
      }
    }
  }

  & .applicant-info {
    & > div {
      &:first-child {
        margin-top: 0;
      }

      & .form-grid {
        margin-right: 20px;
        border-bottom: 1px solid ${(props) => props.theme.PrimaryBorderColor};

        & .single-form {
          border-bottom: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          margin-bottom: -1px;

          &:last-child {
            border-bottom: 0;
          }

          @media (max-width: 550px) {
            border-bottom: none;
          }
        }
      }
    }
  }

  & .view-application-box {
    & .flexi-tab-content-active {
      height: calc(100vh - 450px) !important;
      padding: 0 0 50px;

      @media (max-width: 500px) {
        height: fit-content;
        overflow: unset;
        padding: 0;
      }
    }

    & .flexi-tab-header-box {
      @media (max-width: 550px) {
        padding: 0 10px;

        & .flexi-tab-label {
          width: min-content;
        }
      }
    }
  }
`;

export const Face = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.Primaryfade};
  background-color: #eee;
  transition: all 0.3s ease-out;
  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-size: cover;
      background-position: center;
    `}
  ${(props) =>
    props.tag &&
    css`
      background-color: ${props.tag};
    `}
        ${(props) =>
    props.status !== "pending" &&
    css`
      opacity: 0.8;
      background-color: ${(props) =>
        transparentize(0, props.theme.PrimaryFade)};
    `}
        border: 3px solid ${(props) => props.statusColor};
`;

export const StatusProps = (props) => {
  let theStatus = { label: "Pending", color: "gray" };
  switch (props?.toLowerCase()) {
    case "accepted":
      theStatus = { label: "Approved", color: Theme.PrimaryGreen };
      break;
    case "declined":
      theStatus = { label: "Declined", color: Theme.PrimaryRed };
      break;
    case "submitted":
      theStatus = { label: "Processing", color: Theme.PrimaryBlue };
      break;
    default:
      theStatus = { label: "Pending", color: "gray" };
      break;
  }
  return theStatus;
};

export const SubmissionNotice = styled.div`
  & .main-form {
    width: 400px;
    box-shadow: #a7a7a791 0px 2px 10px 0px;
    background: #fff;
    padding: 35px;
    border-radius: 5px;
  }
`;

export const FormWrapper = styled(Box)`
  & .applicant-info {
    margin-top: 38px;
    & > div {
      &:first-child {
        margin-top: 0;
      }

      & .form-grid {
        margin-right: 20px;
        border-bottom: 1px solid ${(props) => props.theme.PrimaryBorderColor};

        & .single-form {
          border-bottom: 1px solid ${(props) => props.theme.PrimaryBorderColor};
          margin-bottom: -1px;

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }

  padding-bottom: 30px;

  .form-actions,
  .print-form {
    @media print {
      display: none;
    }
  }
`;

export const FormStyle = styled.div`
  @media (max-width: 550px) {
    & .passport-img {
      margin: auto -1.6rem -15.7rem auto;
    }
    & img.passport {
      width: 130px !important;
    }
  }
`;
