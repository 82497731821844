import React from 'react';
import Paystack from "../assets/images/paystack-logo.png";
import Remita from "../assets/images/remita-logo.png";
import Stripe from "../assets/images/stripe.png";
import Interswitch from "../assets/images/interswitch-logo.png";

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    initialValue
  );
};

export const getPhone = (user) => {
  const contactEmail = user?.contacts?.find(
    (c) => c.contactType === "PHONE_NUMBER"
  );
  return contactEmail?.contact || "";
};

const getLocalItem = (item) => {
  const orgName = window.location.pathname.split("/")[1];
  return localStorage.getItem(`applicant_app_${orgName}_${item}`);
};

const setLocalItem = (item, value) => {
  const orgName = window.location.pathname.split("/")[1];
  return localStorage.setItem(`applicant_app_${orgName}_${item}`, value);
};

const clearLocalItem = (item) => {
  const orgName = window.location.pathname.split("/")[1];
  return localStorage.clear(`applicant_app_${orgName}_${item}`);
};

export const getLocalAccessToken = () => getLocalItem("access_token");
export const getLocalRefreshToken = () => getLocalItem("refresh_token");
export const getLocalUser = () => getLocalItem("user");

export const setLocalAccessToken = (value) =>
  setLocalItem("access_token", value);
export const setLocalRefreshToken = (value) =>
  setLocalItem("refresh_token", value);
export const setLocalUser = (value) => setLocalItem("user", value);

export const clearLocalAccessToken = () => clearLocalItem("access_token");
export const clearLocalRefreshToken = () => clearLocalItem("refresh_token");
export const clearLocalUser = () => clearLocalItem("user");

export const formatAddress = (val) => {
  if (typeof val === "string") return val;
  return (val || [])?.reverse()?.join(" LGA of ");
};

export const HIERARCHICAL_GROUP = "Hierarchical Group";

export const checkPaymentGateway = (platform) => {
  switch (platform?.toLowerCase()) {
    case "remita":
      return <img height="16px" src={Remita} alt="Remita Logo" />;
    case "paystack":
      return <img height="16px" src={Paystack} alt="Paystack Logo" />;
    case "interswitch":
      return <img height="16px" src={Interswitch} alt="Interswitch Logo" />;
    case "stripe":
      return <img height="16px" src={Stripe} alt="Stripe Logo" />;
    default:
      return null;
  }
};
