import React from "react";
import { Layout, Grid, Box, Spacer, Text, Button } from "@flexisaf/flexibull2";
import { darken } from "polished";
import SuccessImage from "../../assets/images/success.svg";

import { SubmissionNotice } from "./style";

export const SubmissionFeedback = ({
  onNext = () => null,
  loading = false,
  nextBtnText = '',
  nextDescription = '',
}) => {
  return (
    <Layout>
      <SubmissionNotice>
        <Box
          display="grid"
          width="100vw"
          height="100vh"
          style={{ placeItems: "center" }}
        >
          <Grid className="main-form" default="1fr" templateRow="1fr 1fr">
            <Box margin=" 0 0 10px 0">
              <img
                width="70px"
                src={SuccessImage}
                alt="successful transaction"
              />
              <Spacer space="10px" />
              <Text
                bold
                uppercase
                color={({ theme }) =>
                  darken(0.2, theme.PrimaryGreen || "#26a640")
                }
              >
                Submission Successful
              </Text>
            </Box>
            <Box>
              <Text block>
                {
                  nextDescription || `
                Cheers!! Your application form submission was successful.
                Proceed to the application dashboard to view your application
                status.`
                }
              </Text>
              <Spacer space="30px" />
              <Button progress={loading} width={160} color="#26a640" bold onClick={onNext}>
                {nextBtnText || 'Go to Dashboard'}
              </Button>
            </Box>
          </Grid>
        </Box>
      </SubmissionNotice>
    </Layout>
  );
};
