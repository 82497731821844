import React, { useEffect, useState } from 'react';
import { Layout as FLayout } from "@flexisaf/flexibull2";
import Footer from 'components/Footer';
import Navbar from 'components/Home/NavBar';
import NoticeCard from 'components/NoticeCard';
import GlobalStyles from 'utils/globalStyles';
import { Theme } from 'utils/theme';
import { useSelector } from 'react-redux';
import { selectApplications } from 'redux/landing/landingSlice';


const NOTICE = 'NOTICE';

export const Layout = ({ showHeader = true, showFooter = true, children }) => {
  const { org } = useSelector(selectApplications);
  const [showNoticeCard, setShowNoticeCard] =  useState(false);
  const themeColor = org?.themeColor;
  const customTheme = {
    ...Theme,
    ...(themeColor !== undefined && { PrimaryColor: themeColor }),
  };

  const handleClose = () => {
    localStorage.setItem(NOTICE, org?.noticeMessage);
    setShowNoticeCard(false);
  }

  useEffect(() => {
    setShowNoticeCard(org?.publishNotice && localStorage.getItem(NOTICE) !== org?.noticeMessage);
  }, [org])

  return (
      <FLayout theme={customTheme}>
        <GlobalStyles />
        {showHeader && (
          <>
          {
            showNoticeCard && (
              <NoticeCard
                published={org?.publishNotice}
                noticeMessage={org?.noticeMessage}
                showNoticeCard
                onClose={handleClose}
              />
            )
          }
            <Navbar org={org} theme={customTheme} />
          </>
        )}
        {children}
        {showFooter && <Footer />}
      </FLayout>
  );
};
