import { createSlice } from "@reduxjs/toolkit";
import config from "utils/config";
import request from "utils/request";
import { Notify } from "@flexisaf/flexibull2/build/notify";
import { isJson } from "utils";


export const necoVerificationSlice = createSlice({
  name: "neco",

  initialState: {
    candidateId: null,
    allExams: [{ examNo: "", examType: "", examYear: null, token: null }],
    allCandidateInfo: [],
    allCandidateResult: [],
    errorFetchingCandidateInfo: {},
    errorFetchingCandidateResult: {},
    isLoadingNeco: false,
    numberOfSittings: 0,
    step: 1,
    verificationStep: false,
    isSecondSitting: false,
  },

  reducers: {
    setCandidateId: (state, { payload }) => {
      state.candidateId = payload;
    },
    setNumberOfSittings: (state, { payload }) => {
      if (state.numberOfSittings < 2) {
        state.numberOfSittings += 1;
      } else state.numberOfSittings = 0;
    },
    setAllExams: (state, { payload }) => {
      state.allExams = payload;
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    setVerificationStep: (state, { payload }) => {
      state.verificationStep = payload;
    },
    setIsSecondSitting: (state, { payload }) => {
      state.isSecondSitting = payload;
    },
    handleChange: (state, { payload }) => {
      const { index, value, name } = payload;
      state.allExams[index][name] = value;
    },
    resetCandidateInfo: (state, { payload }) => {
      state.allCandidateInfo = [];
    },
    resetAllExams: (state, { payload }) => {
      state.allExams = [
        { examNo: "", examType: "", examYear: null, token: null },
      ];
    },
    fetchCandidateInfoStart: (state, { payload }) => {
      state.isLoadingNeco = payload;
    },
    fetchCandidateResultStart: (state, { payload }) => {
      state.isLoadingNeco = payload;
    },
    fetchCandidateInfoSuccess: (state, { payload }) => {
      if (state.allCandidateInfo.length < 2) {
        state.allCandidateInfo.push(payload);
      }
      if (state.allCandidateInfo.length >= 2) {
        state.allCandidateInfo.shift();
        state.allCandidateInfo.push(payload);
      }
    },
    fetchCandidateResultSuccess: (state, { payload }) => {
      state.allCandidateResult.push(payload);
    },
    fetchCandidateInfoFailure: (state, { payload }) => {
      state.errorFetchingCandidateInfo = payload;
    },
    fetchCandidateResultFailure: (state, { payload }) => {
      state.errorFetchingCandidateResult = payload;
    },
    setIsLoadingNeco: (state, { payload }) => {
      state.isLoadingNeco = payload;
    },
  },
});

export const {
  setCandidateId,
  setStep,
  setNumberOfSittings,
  setAllExams,
  setVerificationStep,
  handleChange,
  resetCandidateInfo,
  resetAllExams,
  fetchCandidateInfoStart,
  fetchCandidateInfoSuccess,
  fetchCandidateInfoFailure,
  fetchCandidateResultStart,
  fetchCandidateResultSuccess,
  fetchCandidateResultFailure,
  setIsLoadingNeco,
  setIsSecondSitting,
} = necoVerificationSlice.actions;

export const fetchCandidateInfo = (data) => async (dispatch) => {
  dispatch(fetchCandidateInfoStart(true));
  const url = `${config.API_BASE_URL}/addons/neco/verify`;
  const res = await request({
    method: "get",
    url: url,
    data,
  });
  if (res.success) {
    dispatch(fetchCandidateInfoStart(false));
    await dispatch(fetchCandidateInfoSuccess(res?.raw));
    dispatch(setStep(3));
  } else {
    const errorMessage= isJson(res.message) ? JSON.parse(res.message).message : res.message
    dispatch(fetchCandidateInfoFailure(res.message));
    dispatch(fetchCandidateInfoStart(false));
    Notify(errorMessage || "Oops! Something went wrong!", {
      position: "top-right",
      status: "error",
    });
  }
};

export const fetchCandidateResult = (data) => async (dispatch) => {
  dispatch(fetchCandidateResultStart(true));
  const url = `${config.API_BASE_URL}/addons/neco/fetch`;
  const res = await request({
    method: "put",
    url: url,
    data,
  });
  if (res.success) {
    dispatch(fetchCandidateResultStart(false));
    await dispatch(fetchCandidateResultSuccess(res?.raw));
    dispatch(setStep(4));
    dispatch(resetAllExams());
  } else {
    let errMessage;
    if (res.statusCode === 409) {
      errMessage = "This exam is already recorded for you. please use a diferent exam number to add moere sittings"
    } else {
      errMessage =  isJson(res.message) ? JSON.parse(res.message).message : res.message
    }
    Notify(errMessage || "Oops! Something went wrong!", {
      position: "top-right",
      status: "error",
    });
    dispatch(fetchCandidateResultStart(false));
    dispatch(fetchCandidateResultFailure(res.message));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectNeco = (state) => state.neco;

export default necoVerificationSlice.reducer;
