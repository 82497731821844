import React, { useState, useEffect } from "react";
import { Box, Text, SplitLoginLayout, Spacer } from "@flexisaf/flexibull2";
import OrgSearch from "assets/images/OrgSearch.svg";
import Logo from "assets/images/saf-logo.svg";
import { NavLink } from 'react-router-dom';
import { OrgSelectWrapper } from "./style";
import debounce from "lodash.debounce";
import request from 'utils/request';
import config from 'utils/config';
import { version } from "version";

const { API_BASE_URL } = config;

export const OrgSelection = ({ title }) => {
  const [searchfield, setSearchfield] = useState("");
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    fetchOrganisations();
  }, []);

  const searchOrganisations = async (orgName) => {
    const url = `${API_BASE_URL}/portals?orgName=${orgName}`;
    const response = await request({
      method: 'get',
      url,
    });
    if (response.success) {
      setOrganisations(response.raw.data);
    } else {
      return;
    }
  };

  const fetchOrganisations = async () => {
    const url = `${API_BASE_URL}/portals`;
    const response = await request({
      method: 'get',
      url,
    });
    if (response.success) {
      setOrganisations(response.raw.data);
    } else {
      return;
    }
  };

  const sendQuery = (query) => searchOrganisations(query);
  const delayedQuery = debounce((q) => sendQuery(q), 1000);
  const onSearchChange = (e) => {
    setSearchfield(e.target.value);
    delayedQuery(e.target.value);
  };

  return (
    <OrgSelectWrapper>
      <SplitLoginLayout
        color="#8266F8"
        image={OrgSearch}
        leftFoot={
          <Box>
            <Text>
              Copyright &copy; {new Date().getFullYear()}{" "}
              <strong>Safapply.</strong> All rights reserved.
            </Text>
          </Box>
        }
        rightTop={<img src={Logo} alt="safpply logo" />}
        rightFoot={
          <Box>
            <Text block>Version {version}</Text>
            <Box>
              Powered by{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://safapply.com/"
              >
                <Text bold color="#8266F8">
                  SAFAPPLY
                </Text>
              </a>{" "}
              a product of{" "}
              <a
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
                href="http://flexisaf.com/"
              >
                <Text bold color="#8266F8">
                  Flexisaf EduSoft
                </Text>
              </a>
            </Box>
          </Box>
        }
      >
        <Box>
          <Text color="#373737" block bold size="1.8rem">
            {title}
          </Text>
          <Spacer space="20px" />
          <Text block color="#707070">
            Seems you did not enter the right link to the SAFAPPLY Applicant
            portal, not to worry you can search and select the right
            Organisation below
          </Text>
          <Box height="1rem" />
          <form action="">
            <Text bold color="#707070" block>
              {" "}
              Search for Organizations/Institution
            </Text>
            <Spacer space="10px" />
            <input
              type="text"
              name="search"
              onChange={onSearchChange}
              value={searchfield}
              placeholder="Please enter organization name e.g Bosu"
            />
            <i className="safapply-search-2"></i>
          </form>
        </Box>
        <Box pad="30px 0">
          <Text bold color="#373737">
            Available Organisations
          </Text>
          <Box>
            <ul>
              {organisations.map((org) => (
                <li key={org.id}>
                  <NavLink to={`/${org.id}`}>{org.name}</NavLink>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      </SplitLoginLayout>
    </OrgSelectWrapper>
  );
};
