import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    *, *::after, *::before {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    html {
        font-size: 62.5%;

        @media (min-width: 64em){
            font-size: 55%;
        }
    }
    body {
        min-width: 100%;
        font-family: ${({ theme }) => theme.PrimaryFont};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
        color: #373737;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5;
    }
    img {
        max-width: 100%
    }

    h1,h2,h3 {
        line-height: 1.2;
    }

    a{
        color: ${({ theme }) => theme.PrimaryColor}
    }

    .container {
        width: 100%;
        max-width: 108.5rem;
        margin: 0 auto;
        place-items: center;
        display: grid;

        .flexi-select {
            // max-width: 90vw;
            width: 100%;
        }
    }

    .container-fluid {
        width: 100%;
        margin: 0 auto;
        place-items: center;
        display: grid;

        .flexi-select {
            width: 100%;
        }
    }

    .flexi-layout {
        display: flex;
        flex-direction: column;
        min-width:100%;
    }
`;

export default GlobalStyles;
