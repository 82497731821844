import { combineReducers } from 'redux';
import landingReducer from 'redux/landing/landingSlice';
import authReducer from 'redux/auth/authSlice';
import personalAreaReducer from 'redux/auth/personalAreaSlice';
import neco from './neco/necoSlice';
import notifications from 'redux/notification/notificationSlice';
import toursReducer from './tours/toursSlice';
export default combineReducers({
  applications: landingReducer,
  auth: authReducer,
  personalArea: personalAreaReducer,
  neco: neco,
  notifications: notifications,
  tours: toursReducer,
});
