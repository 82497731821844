import React from "react";
import { Box, Input, Spacer, Grid, Text } from "@flexisaf/flexibull2";
import Footer from "./NecoVerificationFooter";
import { useSelector, useDispatch } from "react-redux";
import { selectNeco, handleChange } from "redux/neco/necoSlice";
import { NecoSelect } from "./neco.style";

const getExamYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1999;
  return new Array(currentYear - startYear + 1).fill().map((_, i) => {
    const value = currentYear - i;
    return { label: `${value}`, value };
  });
};

const NecoVerificationForm = ({ hasTwoSittings }) => {
  const { allExams, isSecondSitting } = useSelector(selectNeco);
  const dispatch = useDispatch();

  return (
    <Box>
      <Box
        pad="20px 50px"
        width="50vw"
        margin="auto"
        style={{ textAlign: "left" }}
      >
        {hasTwoSittings && (
          <>
            <Text block bold color="#707070">
              {" "}
              { !isSecondSitting ? "FIRST SITTING" : "SECOND SITING"}
            </Text>
            <Spacer space="24px" />
          </>
        )}
        <Grid default="repeat(2, 1fr)">
          <Input
            name="examNo"
            label="examination number"
            placeholder="enter exam number"
            value={allExams[0].examNo}
            onChange={(event) =>
              dispatch(
                handleChange({
                  index: 0,
                  name: event.target.name,
                  value: event.target.value,
                })
              )
            }
            required
            spaceTop
            spaceBottom
          />
          <NecoSelect
            error={""}
            name="examType"
            block
            spaceTop
            required
            spaceBottom
            options={[
              { label: "SSCE EXT", value: "SSCE_EXT" },
              { label: "SSCE INT", value: "SSCE_INT" },
              { label: "BECE", value: "BECE" },
              { label: "NCEE", value: "NCEE" },
            ]}
            label="Examination Type"
            onChange={(examType) =>
              dispatch(
                handleChange({
                  index: 0,
                  name: "examType",
                  value: examType.value,
                })
              )
            }
          />
          <NecoSelect
            name="examYear"
            label="Examination Year"
            options={getExamYearOptions()}
            block
            required
            spaceTop
            spaceBottom
            onChange={(examYear) =>
              dispatch(
                handleChange({
                  index: 0,
                  name: "examYear",
                  value: examYear.value,
                })
              )
            }
          />
        </Grid>
        <Spacer space="20px" />
      </Box>
      <div className="form-footer">
        <Footer prev={1} next={3} />
      </div>
    </Box>
  );
};

export default NecoVerificationForm;
