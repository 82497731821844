import { createSlice } from "@reduxjs/toolkit";
import config from "utils/config";
import request from "utils/request";

export const notificationSlice = createSlice({
  name: "notification",

  initialState: {
    notifications: { data: [] },
    fetchingNotifications: false,
    markAsRead: null,
    markAsReadError: null,
  },

  reducers: {
    fetchNotificationStart: (state, { payload }) => {
      state.fetchingNotifications = true;
    },
    fetchNotificationSuccess: (state, { payload }) => {
      state.fetchingNotifications = false;
      state.notifications = payload;
    },
    fetchNotificationFailure: (state, { payload }) => {
      state.fetchingNotifications = false;
    },
    markNotificationAsReadSuccess: ( state, { payload }) => {
      state.markAsRead = true;
    },
    markNotificationAsReadFailure: ( state, { payload }) => {
      state.markAsReadError = payload
      state.markAsRead = false;
    },
  },
});

export const {
  fetchNotificationStart,
  fetchNotificationSuccess,
  fetchNotificationFailure,
  markNotificationAsReadStart,
  markNotificationAsReadSuccess,
  markNotificationAsReadFailure,
} = notificationSlice.actions;

export const fetchNotifications = () => async (dispatch) => {
  await dispatch(fetchNotificationStart());
  const url = `${config.API_BASE_URL}/applicants/notifications?read=${false}`;
  const res = await request({ method: "get", url });
  if (res.success) {
    await dispatch(fetchNotificationSuccess(res.raw));
  } else {
    dispatch(fetchNotificationFailure(res.message));
  }
};

export const markNotificationAsSeen = () => async (dispatch) => {
  const url = `${config.API_BASE_URL}/notifications`;
  const res = await request({
    method: "patch",
    url,
    data: {
      read: true,
    },
  });
  if (res.success) {
      await dispatch(markNotificationAsReadSuccess());
  } else {
    dispatch(markNotificationAsReadFailure(res.message));
  }
};

export const selectNotifications = (state) => state.notifications;

export default notificationSlice.reducer;
