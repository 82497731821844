import { PaymentFeedback } from "components/PaymentModal/PaymentFeedback";
import { SubmissionFeedback } from "components/ApplicantPreview/SubmissionFeedback";
import { FormPreview } from "components/ApplicantPreview/FormPreview";
import PrintPreview from "components/ApplicantPreview/PrintPreview";
import { getCurrentUser } from "redux/auth/authSlice";
import React, { lazy, useEffect, Suspense } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { getLocalAccessToken } from "utils/helpers";
import { Box, Loader } from '@flexisaf/flexibull2';

const GoogleAuth = lazy(() => import("containers/auth/Login/googleAuth"));
const LandingPage = lazy(() => import("containers/landing/LandingPage"));
const SearchPage = lazy(() => import("containers/landing/SearchPage"));
const LoginPage = lazy(() => import("containers/auth/Login"));
const SignUpPage = lazy(() => import("containers/auth/SignUp/SignUpPage"));
const ChangePassword = lazy(() =>
  import("containers/auth/Login/ChangePassword")
);
const NotFound = lazy(() => import("components/NotFound"));
const PersonalArea = lazy(() =>
  import("containers/auth/PersonalArea/PersonalArea")
);
const PersonalAreaPayment = lazy(() =>
  import("containers/auth/PersonalArea/pay/Pay")
);
const Profile = lazy(() => import("containers/auth/Profile"));
const RefereeForm = lazy(() => import("containers/referee"));
const ApplicationForm = lazy(() => import("containers/auth/Application"));
const TransactionPage = lazy(() =>
  import("containers/transaction/TransactionsPage")
);

const LazyLoader = () => (
  <Box width="100%" height="calc(100vh - 200px)" vAlign>
    <Box width="100%" align="center">
      <Loader />
    </Box>
  </Box>
);

const Routes = () => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();

  const authToken = getLocalAccessToken();

  useEffect(() => {
    dispatch(getCurrentUser(params.orgName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PrivateRoute = ({ component: Component, ...props }) => (
    <Route
      {...props}
      render={(props) =>
        authToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/${params.orgName}/login`} />
        )
      }
    ></Route>
  );

  const PublicRoute = ({ component: Component, ...props }) => (
    <Route
      {...props}
      render={(props) =>
        authToken ? (
          <Redirect to={`/${params.orgName}/personal-area`} />
        ) : (
          <Component {...props} />
        )
      }
    ></Route>
  );

  return (
    <Suspense fallback={<LazyLoader />}>
      <Switch>
        <Route exact path="/:orgName" component={LandingPage} />
        <Route path="/:orgName/search" component={SearchPage} />
        <Route
          exact
          path="/:orgName/personal-area/pay/:candidateId"
          component={PersonalAreaPayment}
        />
        <Route
          path="/:orgName/payment-status"
          component={PaymentFeedback}
        />
        <Route
          path="/:orgName/referee"
          component={RefereeForm}
        />
        <PublicRoute path="/:orgName/login" component={LoginPage} />
        <PublicRoute path="/:orgName/auth" component={GoogleAuth} />
        <PublicRoute path="/:orgName/create-account" component={SignUpPage} />
        <PublicRoute path="/:orgName/reset-request" component={ChangePassword} />
        <PrivateRoute path="/:orgName/personal-area" component={PersonalArea} />
        <PrivateRoute path="/:orgName/profile" component={Profile} />
        <PrivateRoute path="/:orgName/transactions" component={TransactionPage} />
        <PrivateRoute
          path="/:orgName/application-form"
          component={ApplicationForm}
        />
        <PrivateRoute
          path="/:orgName/submission-status"
          component={SubmissionFeedback}
        />
        <PrivateRoute path="/:orgName/form-preview" component={FormPreview} />
        <PrivateRoute path="/:orgName/print-preview" component={PrintPreview} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
