import React from "react";
import { useSelector } from "react-redux";
import { selectApplications } from "redux/landing/landingSlice";
import { Button, Text, Box, Spacer } from "@flexisaf/flexibull2";
import { version } from "version";
import { FooterWrapper } from "./style";

const Footer = () => {
  const { org } = useSelector(selectApplications);

  return (
    <FooterWrapper>
      <div className="container">
        <div className="footer-main">
          <h3>
            <span>Need Help?</span> Run through the step by step registration
            guide
          </h3>

          <Button
            onClick={() =>
              window.open(
                "http://support.safapply.com/support/solutions/67000385938"
              )
            }
            font="1.6rem"
            width="200"
            className="reg-button"
          >
            Registration Guide
          </Button>
        </div>
        {(org?.contacts?.length !== 0 || org?.address) && (
          <Box width="100%">
            <Box
              width="100%"
              margin="20px 0"
              background="#ffffff4d"
              height="1px"
            />
            <Box width="100%" pad="0 20px" className="contact-section">
              <Box>
                <Text capitalize block bold size="14px">
                  Address
                </Text>
                <Spacer space="8px" />
                <div>
                  <i className="address-icon safapply-location"></i>
                  <Text color="#ffffffb3">{org?.address}</Text>
                </div>
              </Box>
              <Spacer space="20px" />
              <Text color="#ffffffb3" bold>
                For organisation / Institution Specific Enquiries please contact
                the following offices
              </Text>
              <Spacer space="20px" />
              <ol className="contact-cover">
                {org?.contacts?.map((contactInfo) => (
                  <li>
                    <Box className="contact-counter">
                      <Text capitalize block bold size="14px">
                        {contactInfo.contact}
                      </Text>
                      <Spacer space="8px" />
                      <a href={"tel:" + contactInfo.phone}>
                        <i className="icon safapply-phone"></i>
                        <Text color="#ffffffb3">{contactInfo.phone}</Text>
                      </a>
                      <Spacer space="4px" />
                      <a href={"mailto:" + contactInfo.email}>
                        <i className="icon safapply-mail"></i>
                        <Text color="#ffffffb3">{contactInfo.email}</Text>
                      </a>
                    </Box>
                  </li>
                ))}
              </ol>
            </Box>
          </Box>
        )}
        <div className="footer-text">
          <p className="copyright">
            <Text>
              Copyright &copy; {new Date().getFullYear()}{" "}
              <strong>
                {" "}
                <a href="https://www.safapply.com/">SAFApply</a>{" "}
              </strong>{" "}
              All rights reserved.{" "}
            </Text>
            <Text>
              Powered by{" "}
              <strong>
                {" "}
                <a href="https://www.flexisaf.com/">FlexiSAF</a>{" "}
              </strong>
            </Text>
          </p>
        </div>
        <Text color="#ffffffb3">
          version: {version}
        </Text>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
