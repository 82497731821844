import React from 'react';
import styled from 'styled-components';
import { readableColor } from 'polished';

const NoticeCard = ({ noticeMessage = '', onClose }) => {
  return (
    <NoticeCardWrapper>
      <div className="container">
        <div className="notice">
          <button className="closeBtn" onClick={onClose}>
            &times;
          </button>
          <h3 className="title">Notice</h3>
          <p className="message">{noticeMessage}</p>
        </div>
      </div>
    </NoticeCardWrapper>
  );
};

export default NoticeCard;

const NoticeCardWrapper = styled.div`
  background-color: ${({ theme }) => theme.PrimaryColor};
  color: ${readableColor(`rgba(0,0,0,0.8)`)};
  padding: 2rem 0;

  & .notice {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    padding: 0 4rem 0 2rem;
    font-size: 14px;
  }

  & .title {
    margin-bottom: 0.8rem;
  }

  & .closeBtn {
    cursor: pointer;
    padding: 0.8rem;
    position: absolute;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 2rem;
    border-radius: 4px;
    color: ${readableColor(`rgba(0,0,0,0.8)`)};
    right: 0;
    top: -0.8rem;
    outline-width: 0;
  }
`;
