import React from 'react';
import styled from 'styled-components';
import Tag from './Tag';

const TagList = ({ lists = [], tagProps }) => {
  return (
    <TagListWrapper>
      {lists.map((list, index) => (
        <Tag label={list.label} key={index} {...tagProps} />
      ))}
    </TagListWrapper>
  );
};

export default TagList;

const TagListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
