import { selectAuth } from "redux/auth/authSlice";
import React from "react";
import { Text, Grid, DropDown } from "@flexisaf/flexibull2";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { candidateLogout } from "redux/auth/authSlice";
import { Header, Nav, NavMenu, OrgLogo, UserBtn } from "./NavbarElements";
import { Avatar } from "../../Layout/layout.style";
import { selectApplications } from "redux/landing/landingSlice";

const NavBar = ({ org, theme }) => {
  const { params } = useRouteMatch();
  const history = useHistory();
  const { user } = useSelector(selectAuth);
  const { imgCacheClearer } = useSelector(selectApplications);
  const dispatch = useDispatch();

  return (
    <Header>
      <Nav>
        <OrgLogo to={`/${params.orgName}`}>
          {org?.logoUrl ? (
            <img src={`${org?.logoUrl}?b=${imgCacheClearer}`} alt="" width="200" height="60" />
          ) : (
            <Text bold uppercase size="20px">
              {params.orgName}
            </Text>
          )}
        </OrgLogo>
        <NavMenu>
          <Grid
            style={{ alignSelf: "center", alignItems: "center" }}
            default="1fr max-content"
            md="1fr"
            sm="1fr"
            columnGap="25px"
            rowGap="25px"
          >
            {!user && <strong
              onClick={() => history.push(`/${params.orgName}/create-account`)}
              style={{ color: theme.PrimaryColor, marginRight: "15px" }}
            >
              Create account
            </strong>}

            <UserBtn
              onClick={() =>
                history.push(
                  `/${params.orgName}/${user ? "personal-area" : "login"}`
                )
              }
            >
              <strong> {user ? "My Applications" : "Login"} </strong>
            </UserBtn>
            
            {user ? (
              <DropDown
                style={{ cursor: "pointer" }}
                label={
                  <ProfileIcon pic="https://as1.ftcdn.net/jpg/01/87/10/40/500_F_187104027_8i2JbFDBB5jB7R65Ce464yRs4gfNbR3Z.jpg" />
                }
                menuAlign="bottom right"
                menuList={[
                  {
                    onClick: () => history.push(`/${params.orgName}/profile`),
                    label: "My Profile",
                  },
                  {
                    onClick: () => {
                      dispatch(
                        candidateLogout(() =>
                          history.push(`/${params.orgName}`)
                        )
                      );
                    },
                    label: "Logout",
                  },
                ]}
              />
            ) : (
              " "
            )}
          </Grid>
        </NavMenu>
      </Nav>
    </Header>
  );
};

export default NavBar;

const ProfileIcon = ({ pic }) => {
  return (
    <Avatar>
      <img src={pic} alt="" />
      <i></i>
    </Avatar>
  );
};
