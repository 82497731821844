import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  Text,
  Box,
  RadioButton,
  Spacer,
} from "@flexisaf/flexibull2";
import { Wrapper, RadioButtonWrapper, FormWrapper  } from "./neco.style";
import { useSelector } from "react-redux";
import CandidateInfo from "./NecoVerificationCandidateInfo";
import CandidateResult from "./NecoVerificationCandidateResult";
import NecoVerificationForm from "./NecoVerificationForm";
import Footer from "./NecoVerificationFooter";
import { selectNeco } from "redux/neco/necoSlice";
import { Theme } from "utils/theme";
import Neco from "assets/images/neco.png";

const NecoVerificationModal = ({
  necoVerificationStep,
  applySuccess,
  handlePaymentSuccess,
  hasPayment,
}) => {
  const [isUsingNeco, setIsUsingNeco] = useState(null);
  const [hasTwosittings, setHasTwosittings] = useState(false);
  const { step } = useSelector(selectNeco);
  return (
    <Modal open={necoVerificationStep} onClose={() => null}>
      <ModalBody
        bgColor="#fff"
        style={{ textAlign: "center", width: "50vw", minWidth: "max-content", height: "740px", maxHeight:"200vh", overflow: "auto" }}
      >
        {step === 1 && (
          <Box>
            <Box pad="10px 30px 0 30px" margin="10px 10px 0">
              <img src={Neco} width="50px" alt="Logo" />
              <Spacer space="16px" />
              <Text size="16px" block bold>
                Input Your NECO exams details here
              </Text>
              <Spacer />
              <Text size="12px" block>
                Kindly ensure to fill in the correct details here
              </Text>
              <Wrapper>
                <Box pad="20px 0px 10px" margin="8px 0 24px" classname="small">
                  <Text bold block>
                    Would you be using NECO result to Apply?
                  </Text>
                  <Spacer space="16px" />
                  <RadioButtonWrapper borderColour={isUsingNeco}>
                    <RadioButton
                      label={
                        <Box>
                          <Text spaceLeft bold>
                            Yes
                          </Text>
                          <Text spaceLeft block>
                            I would be using NECO to apply
                          </Text>
                        </Box>
                      }
                      color={
                        isUsingNeco ? Theme.PrimaryColor : Theme.PrimaryGrey
                      }
                      checked={isUsingNeco}
                      onChange={() => setIsUsingNeco(true)}
                    />
                  </RadioButtonWrapper>
                  <Spacer />
                  <RadioButtonWrapper borderColor={!isUsingNeco}>
                    <RadioButton
                      label={
                        <Box>
                          <Text spaceLeft bold>
                            No
                          </Text>
                          <Text block>I would not be using NECO to apply</Text>
                        </Box>
                      }
                      color={
                        isUsingNeco ? Theme.PrimaryGrey : Theme.PrimaryColor
                      }
                      checked={isUsingNeco === false}
                      onChange={() => setIsUsingNeco(false)}
                    />
                  </RadioButtonWrapper>
                </Box>
                {isUsingNeco && (
                  <Box>
                    <Box pad="10px 0px" margin="8px 0 24px">
                      <Text bold block>
                        How many sittings?
                      </Text>
                      <Spacer space="16px" />
                      <RadioButtonWrapper borderColour={!hasTwosittings}>
                        <RadioButton
                          label={
                            <Text spaceLeft bold>
                              One
                            </Text>
                          }
                          color={Theme.PrimaryColor}
                          checked={!hasTwosittings}
                          onChange={() => setHasTwosittings(false)}
                        />
                      </RadioButtonWrapper>
                      <Spacer />
                      <RadioButtonWrapper borderColor={hasTwosittings}>
                        <RadioButton
                          label={
                            <Text spaceLeft bold>
                              Two
                            </Text>
                          }
                          color={Theme.PrimaryColor}
                          checked={hasTwosittings}
                          onChange={() => setHasTwosittings(true)}
                        />
                      </RadioButtonWrapper>
                    </Box>
                  </Box>
                )}
              </Wrapper>
            </Box>
            <Footer
              isUsingNeco={isUsingNeco}
              applySuccess={applySuccess}
              hasPayment={hasPayment}
              handlePaymentSuccess={handlePaymentSuccess}
              prev={0}
              next={2}
            />
          </Box>
        )}
        {step === 2 && (
          <Box>
            <Box pad="20px 30px 0 30px" margin="20px 10px 0">
              <img src={Neco} width="50px" alt="Logo" />
              <Spacer space="16px" />
              <Text size="16px" block bold>
                Input Your NECO exams details here
              </Text>
              <Spacer />
              <Text size="12px" block>
                Kindly ensure to fill in the correct details here
              </Text>
              <Spacer space="16px" />
              <Spacer />
              <Box
                pad="0 0 12px"
                style={{
                  borderTop: "1px solid",
                  width: "50vw",
                  margin: "auto",
                  zIndex: "1000",
                }}
              />
                <NecoVerificationForm hasTwoSittings={hasTwosittings} />
            </Box>
          </Box>
        )}
        {step === 3 && (
          <Box>
            <Box pad="20px 30px 0 30px" margin="20px 10px">
              <img src={Neco} width="50px" alt="Logo" />
              <Spacer space="16px" />
              <Text size="16px" block bold>
                Result Confirmation
              </Text>
              <Spacer />
              <Text size="12px" block>
                Please confirm that the following information is accurate then
                proceed
              </Text>
              <Spacer space="16px" />
              <Box
                style={{
                  borderTop: `1px solid ${Theme.PrimaryGrey}`,
                  width: "50vw",
                  margin: "auto",
                  zIndex: "1000",
                }}
              />
              <FormWrapper>
                <CandidateInfo />
              </FormWrapper>
            </Box>
          </Box>
        )}
        {step === 4 && (
          <Box>
            <Box pad="20px 30px 0 30px" margin="20px 10px 0">
              <img src={Neco} width="50px" alt="Logo" />
              <Spacer space="16px" />
              <Text size="16px" block bold>
                Result Confirmation
              </Text>
              <Spacer />
              <Text size="12px" block>
                Please confirm that the following information is accurate then
                proceed
              </Text>
              <Spacer space="16px" />
              <Box
                style={{
                  borderTop: `1px solid ${Theme.PrimaryGrey}`,
                  width: "50vw",
                  margin: "auto",
                  zIndex: "1000",
                }}
              />
              <CandidateResult />
            </Box>
            <Footer prev={3} next={5} hasTwosittings={hasTwosittings} hasPayment={hasPayment} applySuccess={applySuccess} />
          </Box>
        )}
        {step === 5 && (
          <Box pad="20px 30px 0 30px" margin="96px 10px 0">
            <img src={Neco} width="50px" alt="Logo" />
            <Spacer space="16px" />
            <Text size="20px" block bold>
              Verified NECO result successfully uploaded!
            </Text>
            <Spacer />
            <Text size="12px" block>
              Your NECO result has been successfully captured!
            </Text>
            <Spacer space="32px" />
            <Footer prev={0} next={6} applySuccess={applySuccess} handlePaymentSuccess={handlePaymentSuccess} />
          </Box>
        )}
      </ModalBody>
    </Modal>
  );
};

export default NecoVerificationModal;
