const config = {
  development: {
    API_BASE_URL: "https://api.safapply.flexisafapps-dev.com",
    FORM_API_BASE_URL: "https://api.fbs.flexisafapps-dev.com",
    FORM_DATA_BASE_URL: "https://api.fds.flexisafapps-dev.com",
    UMS_BASE_URL:'https://api.ums.flexisafapps-dev.com',
    GA_MEASUREMENT_ID: "G-HRJV7M6CDB",
    GTM_ID: "GTM-N7MC62V",
    BUGSNAG_API_KEY: "45b73dfdba7c852d9d893a50f631c7fb",
    ADMIN_URL: 'https://admin.safapply.flexisafapps-dev.com',
  },
  staging: {
    API_BASE_URL: "https://api.safapply.flexisafapps-stage.com",
    FORM_API_BASE_URL: "https://api.fbs.flexisafapps-stage.com",
    FORM_DATA_BASE_URL: "https://api.fds.flexisafapps-stage.com",
    UMS_BASE_URL:'https://api.ums.flexisafapps-stage.com',
    GA_MEASUREMENT_ID: "G-QQM8F6K3C0",
    GTM_ID: "GTM-T5ZM2X5",
    BUGSNAG_API_KEY: "bbfce6d1011976d01d6784e07f12133e",
    ADMIN_URL: 'https://safapply.product.flexisaf-stage.com',
  },
  production: {
    API_BASE_URL: "https://api.safapply.com",
    FORM_API_BASE_URL: "https://form-builder-api.component.flexisaf.com",
    FORM_DATA_BASE_URL: "https://form-data-api.component.flexisaf.com",
    UMS_BASE_URL:'https://ums-api.component.flexisaf.com',
    GA_MEASUREMENT_ID: "G-1F4E92S5HV",
    GTM_ID: "GTM-WT9HMD5",
    BUGSNAG_API_KEY: "8fb8c1d318c3b1c80263e35ad82075b3",
    ADMIN_URL: 'https://admin.safapply.com',
  },
};

export default config[
  process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development'
];