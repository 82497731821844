import { Notify } from "@flexisaf/flexibull2/build/notify";
import { createSlice } from "@reduxjs/toolkit";
import request from "utils/request";
import config from "utils/config";
import { getLocalUser } from "utils/helpers";

export const personalAreaSlice = createSlice({
  name: "personalArea",

  initialState: {
    listViewCandidates: {
      data: [],
    },
    gridViewCandidates: {
      data: [],
    },
    currentCandidate: null,
    currentApplication: null,
    checkingEligibility: false,
    isFetchingCandidates: false,
    isFetchingListViewCandidates: false,
    isFetchingGridViewCandidates: false,
    transactionHistory: {
      data: [],
    },
    isFetchingtransactionHistory: false,
    isQueryingStatus: false,
    error: null,
  },

  reducers: {
    checkEligibilityStart: (state, { payload }) => {
      state.checkingEligibility = true;
    },
    checkEligibilitySuccess: (state, { payload }) => {
      state.checkingEligibility = false;
    },
    checkEligibilityFailure: (state, { payload }) => {
      state.error = payload;
      state.checkingEligibility = false;
    },
    setCurrentCandidate: (state, { payload }) => {
      state.currentCandidate = payload;
    },
    setCurrentApplication: (state, { payload }) => {
      state.currentApplication = payload;
    },
    fetchCandidatesStart: (state, { payload }) => {
      state.isFetchingCandidates = true;
    },
    fetchListViewCandidates: (state, { payload }) => {
      state.isFetchingListViewCandidates = true;
    },
    fetchGridViewCandidates: (state, { payload }) => {
      state.isFetchingGridViewCandidates = true;
    },
    fetchCandidatesSuccess: (state, { payload }) => {
      state.isFetchingCandidates = false;
      state.listViewCandidates = payload;
      state.gridViewCandidates = payload;
    },
    fetchListViewCandidatesSuccess: (state, { payload }) => {
      state.isFetchingListViewCandidates = false;
      state.listViewCandidates = payload;
    },
    fetchGridViewCandidatesSuccess: (state, { payload }) => {
      state.isFetchingGridViewCandidates = false;
      state.gridViewCandidates = {
        ...state.gridViewCandidates,
        data: [...state.gridViewCandidates.data, ...payload.data],
      };
    },
    fetchCandidatesFail: (state, { payload }) => {
      state.isFetchingCandidates = false;
      state.error = payload;
    },
    fetchTransactionHistoryStart: (state, { payload }) => {
      state.isFetchingtransactionHistory = true;
    },
    fetchTransactionHistorySuccess: (state, { payload }) => {
      state.isFetchingtransactionHistory = false;
      state.transactionHistory = payload;
    },
    fetchTransactionHistoryFail: (state, { payload }) => {
      state.isFetchingtransactionHistory = false;
      state.error = payload;
    },
    queryTransactionStart: (state, { payload }) => {
      state.isQueryingStatus = true;
    },
    queryTransactionSuccess: (state, { payload }) => {
      const transactionsClone = [...state.transactionHistory.data];
      transactionsClone.forEach((transaction) => {
        if (transaction.reference === payload.transactionRef) {
          transaction.status = payload.status;
        }
      });
      state.isQueryingStatus = false;
      state.transactionHistory = {
        ...state.transactionHistory,
        data: [...transactionsClone],
      };
    },
    queryTransactionFail: (state, { payload }) => {
      state.isQueryingStatus = false;
      state.error = payload;
    },
  },
});

export const {
  setCurrentCandidate,
  setCurrentApplication,
  checkEligibilityStart,
  checkEligibilitySuccess,
  checkEligibilityFailure,
  fetchCandidatesStart,
  fetchListViewCandidates,
  fetchGridViewCandidates,
  fetchCandidatesSuccess,
  fetchListViewCandidatesSuccess,
  fetchGridViewCandidatesSuccess,
  fetchCandidatesFail,
  fetchTransactionHistoryStart,
  fetchTransactionHistorySuccess,
  fetchTransactionHistoryFail,
  queryTransactionStart,
  queryTransactionSuccess,
  queryTransactionFail,
} = personalAreaSlice.actions;

const viewTypes = {
  BOTH: "both",
  LIST: "list",
  GRID: "grid",
};

export const fetchCandidates =
  ({ pageNumber = 0, pageSize = 10, type, searchTerm }, cb) =>
  async (dispatch) => {
    if (type === viewTypes.BOTH) {
      await dispatch(fetchCandidatesStart());
    } else if (type === viewTypes.GRID) {
      await dispatch(fetchGridViewCandidates());
    } else {
      await dispatch(fetchListViewCandidates());
    }
    const url = `${
      config.API_BASE_URL
    }/applicants/candidates?page=${pageNumber}&size=${pageSize}${
      searchTerm ? `&keyword=${searchTerm}` : ""
    }`;
    
    const response = await request({
      method: "get",
      url,
    });

    if (response.success) {
      const { raw } = response;
      if (!raw.resultTotal && typeof cb === 'function') {
        cb();
      }
      if (type === viewTypes.BOTH) {
        await dispatch(fetchCandidatesSuccess(raw));
      } else if (type === viewTypes.GRID) {
        await dispatch(fetchGridViewCandidatesSuccess(raw));
      } else {
        await dispatch(fetchListViewCandidatesSuccess(raw));
      }
    } else {
      dispatch(fetchCandidatesFail(response.message));
    }
  };

export const fetchTransactionHistory =
  ({ pageNumber, pageSize, name, platform, status }) =>
  async (dispatch) => {
    await dispatch(fetchTransactionHistoryStart());
    const userInfo = JSON.parse(getLocalUser());
    const url = `${config.API_BASE_URL}/applicants/payments?applicantId=${
      userInfo.id
    }&page=${pageNumber}&size=${pageSize || 10}${name ? `&name=${name}` : ""}${
      platform ? `&platform=${platform.toUpperCase()}` : ""
    }${status ? `&status=${status.toUpperCase()}` : ""}`;
    const response = await request({
      method: "get",
      url,
    });
    if (response.success) {
      const { raw } = response;
      await dispatch(fetchTransactionHistorySuccess(raw));
    } else {
      dispatch(fetchTransactionHistoryFail(response.message));
    }
  };

export const queryTransactionStatus = (ref, cb, successCb = () => null) => async (dispatch) => {
  await dispatch(queryTransactionStart());
  const url = `${config.API_BASE_URL}/payments/status?ref=${ref}`;
  const response = await request({
    method: "get",
    url,
  });
  if (response.success) {
    const { raw } = response;
    await dispatch(queryTransactionSuccess(raw));
    if (raw.status === "PENDING") {
      Notify("Transaction is still pending", {
        position: "top-right",
        status: "info",
      });
    } else {
      Notify("Transaction status updated successfully", {
        position: "top-right",
        status: "success",
      });
      successCb(raw)
      setTimeout(() => {
        dispatch(fetchCandidates({
          pageNumber: 0,
          pageSize: 10,
          type: "both",
        }));
      }, 3000);
    }
    cb(raw.status);
  } else {
    dispatch(queryTransactionFail(response.message));
    Notify("Payment platform is currently down kindly retry later", {
      position: "top-right",
      status: "error",
    });
  }
};

export const checkEligibility =
  (orgId, identifier, appId, callBack) => async (dispatch) => {
    await dispatch(checkEligibilityStart());
    const url = `${config.API_BASE_URL}/portals/${orgId}/check-eligible?primaryIdentifier=${identifier}&applicationId=${appId}`;
    const response = await request({
      method: "get",
      url,
    });
    if (response.success) {
      const { raw } = response;
      await dispatch(checkEligibilitySuccess());
      callBack(raw);
    } else {
      dispatch(checkEligibilityFailure(response.message));
      Notify(response.message || "You are not eligible to apply", {
        position: "top-right",
        status: "error",
        duration: 8000,
      });
      callBack(null);
    }
  };

export const saveCurrentCandidate = (data) => async (dispatch) => {
  await dispatch(setCurrentCandidate(data));
};

export const saveCurrentApplication = (data) => async (dispatch) => {
  await dispatch(setCurrentApplication(data));
};

export const selectPersonalArea = (state) => state.personalArea;

export default personalAreaSlice.reducer;
