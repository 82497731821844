import { configureStore } from '@reduxjs/toolkit';
import rootReducers from './rootReducers';
import { persistStore } from 'redux-persist';


export const store = configureStore({
  reducer: rootReducers,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducers', () => {
    const newRootReducer = require('./rootReducers').default;
    store.replaceReducer(newRootReducer);
  });
}

export const persistor = persistStore(store)
// export default store;
