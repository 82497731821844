import styled from "styled-components";
import { Box, Button, ModalFooter } from "@flexisaf/flexibull2";
import { pulseAnimation } from "components/Layout/layout.style";
import { Theme } from "utils/theme";

export const LayoutBox = styled(Box)`
  & .search-header {
    display: grid;
    grid-template-columns: 1fr max-content;
    justify-content: space-between;
    grid-gap: 20px;

    & .section {
      border-bottom: 1px dashed ${({ theme }) => theme.PrimaryGreyLight};
    }

    @media (max-width: 850px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    .flexi-select {
      width: 100%;
    }

    & .dashboard-search {
      display: flex;
      width: 100%;
      max-width: 35rem;

      @media (max-width: 850px) {
        flex-direction: column;
        max-width: 100%;

        .main-search {
          width: 100%;
          margin-bottom: 20px;
        }
      }

      & .filter-button {
        border: 1px solid #d8d8e3;
        color: #9e9eb7;
        background: #fff;
        min-width: 170px;

        @media (max-width: 850px) {
          margin-top: 10px;
        }
      }
    }

    & .button-toggle {
      @media (max-width: 800px) {
        display: none;
      }
      & .active {
        background-color: ${({ theme }) => theme.PrimaryColor};
        color: #fff;
      }
      & .flexi-button {
        &:first-child {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  & .table-layout {
    display: none;
  }

  .layout-grid {
    .card-cover {
      display: block;
    }
    .table-layout {
      display: none;
    }
  }

  .layout-list {
    .card-cover {
      display: none;
    }
    .table-layout {
      display: block;
    }
  }

  @media (max-width: 500px) {
    .card-cover {
      display: block !important;
    }
    .table-layout {
      display: none !important;
    }
  }

  .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0.5rem 0 2rem 0;

    & .applicant-card {
      padding: 20px 20px 0;
      max-width: 350px;

      & .status-bar {
        border-radius: 3px;
        grid-template-columns: 60px auto;
      }
    }

    @media (max-width: 650px) {
      grid-template-columns: 1fr;
      margin: 0.5rem 0 6rem 0;

      .applicant-card {
        max-width: 100%;
      }
    }

    @media (min-width: 650px) {
      grid-template-columns: 1fr 1fr;

      .applicant-card {
        max-width: 100%;
      }
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);

      .applicant-card {
        max-width: 100%;
      }
    }

    @media (min-width: 1400px) {
      grid-template-columns: repeat(4, 1fr);

      .applicant-card {
        max-width: 100%;
      }
    }

    @media (min-width: 1800px) {
      grid-template-columns: repeat(5, 1fr);

      .applicant-card {
        max-width: 100%;
      }
    }

    @media (min-width: 2200px) {
      grid-template-columns: repeat(6, 1fr);

      .applicant-card {
        max-width: 100%;
      }
    }

    @media (min-width: 2700px) {
      grid-template-columns: repeat(7, 1fr);

      .applicant-card {
        max-width: 100%;
      }
    }
  }

  .layout-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .search-course {
    margin: auto 0;
    transform: scale(1);
    animation: ${pulseAnimation} 2s infinite;
    @media (max-width: 1000px) {
      margin-top: 20px;
    }
  }
`;

export const ModalTitle = styled.h2`
  font-weight: 500;
  margin-left: 37px;
  margin-top: 4rem;
`;

export const StyledFooter = styled(ModalFooter)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 3rem;
  background-color: #fff;
`;

export const ClearBtn = styled(Button)`
  background-color: #fff;
  border-color: transparent;
  margin-right: 1rem;
  color: ${({ theme }) => theme.PrimaryGrey};
`;

export const TourWrapper = styled.div`
font-family: 'Inter';
text-align: left;
color: #737373;

  & .title {
    font-size: 1rem;
    font-weight: 600;
  }

  & .body {
    font-size: .8rem;
    font-weight: 400;
    /* line-height: 120%; */
  }

  & a {
    /* cursor: pointer; */
    text-decoration: underline;
  }

  & a:link {
    color: ${props => props.primaryColor || Theme.PrimaryColor}
  }
`;