const necoGradingSystem = {
  A1: 'Excellent',
  B2: 'Very good',
  B3: 'Good',
  C4: 'Credit',
  C5: 'Credit',
  C6: 'Credit',
  D7: 'Pass',
  E8: 'Pass',
  F9: 'Fail',
};

export const findGradeRemark = (grade) => {
  const gradingSystemArr = Object.keys(necoGradingSystem);
  const foundGrade = gradingSystemArr.find((g) => g === grade.toUpperCase());
  return necoGradingSystem[foundGrade];
};
