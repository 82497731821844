import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DropDown, Button } from "@flexisaf/flexibull2";
import { selectApplications } from 'redux/landing/landingSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { candidateLogout } from 'redux/auth/authSlice';
import { OrgLogo } from 'components/Home/NavBar/NavbarElements';
import { Avatar, HeaderWrapper } from './layout.style'

import usePersonAreaTour from 'hooks/tours/usePersonalAreaTour';

const Header = () => {
  const { params } = useRouteMatch();
  const { org: { logoUrl, name: orgName }, imgCacheClearer } = useSelector(selectApplications);
  const { runTour } = usePersonAreaTour();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <HeaderWrapper>
      <div className="left-nav">
        <OrgLogo to={`/${params.orgName}`}>
          {logoUrl ? (
            <img src={`${logoUrl}?b=${imgCacheClearer}`} alt="" />
          ) : (
            <h1>{orgName}</h1>
          )}
        </OrgLogo>
      </div>
      <div className="right-nav">
        <Button 
          pale
          onClick={() => runTour()} 
          style={{ cursor: 'pointer' }}
        >
          Tour Guide
        </Button>
        <DropDown
          style={{ cursor: 'pointer', marginLeft: '30px' }}
          label={
            <ProfileIcon pic="https://as1.ftcdn.net/jpg/01/87/10/40/500_F_187104027_8i2JbFDBB5jB7R65Ce464yRs4gfNbR3Z.jpg" />
          }
          menuAlign="bottom right"
          menuList={[
            {
              onClick: () => history.push(`/${params.orgName}/profile`),
              label: 'My Profile',
            },
            {
              onClick: () => {
                dispatch(candidateLogout(() => history.push(`/${params.orgName}`)))
              },
              label: 'Logout',
            },
          ]}
        />
      </div>
    </HeaderWrapper>
  );
};

export default Header;

const ProfileIcon = ({ pic }) => {
  return (
    <Avatar>
      <img src={pic} alt="" />
      <i></i>
    </Avatar>
  );
};

