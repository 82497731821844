const moment = require('moment-timezone');

export const MODAL_SIZE = {
  small: "950px",
  big: "90vw",
};

const getFieldKey = (props) => {
  const myArr = [];
  if (isNaN(props.duplicateSectionNumber))
    myArr.push(props.duplicateSectionNumber);
  if (isNaN(props.duplicateRowNumber)) myArr.push(props.duplicateRowNumber);
  myArr.push(props.fieldId);
  return myArr.filter((a) => a).join("-");
};

export const processForm = (formSchema = {}, duplicateFields = {}) => {
  if (!formSchema?.schema) return [];
  if (Object.keys(formSchema?.schema).length > 0) {
    const { sections } = formSchema.schema.layout;
    const candidateInfo = [];
    sections.forEach((sect) => {
      const secRows = !Array.isArray(sect.rows) ? [sect.rows] : sect.rows;
      const duplicateSecIds = duplicateFields?.sections?.[sect.id] || [];
      const newSecs = [
        { id: sect.id, rows: secRows },
        ...duplicateSecIds.map((id, index) => ({
          id,
          sectionNumber: index + 1,
          rows: [...secRows],
        })),
      ];
      newSecs.forEach((l) => {
        const currentSection = {};
        const sectionNumber = l.sectionNumber ? ` (${l.sectionNumber})` : "";
        currentSection.title = `${sect.title}${sectionNumber}`;
        currentSection.description = l.description;
        currentSection.refereeSectionType = sect.refereeSectionType;

        l.rows.forEach((r) => {
          const cls = !Array.isArray(r.cols) ? [r.cols] : r.cols;

          const duplicateRowIds =
            duplicateFields?.rows?.[`${l.id}-${r.id}`] || [];
          const newRows = [
            { id: r.id, cols: cls },
            ...duplicateRowIds.map((id, index) => ({
              id,
              rowNumber: index + 1,
              cols: [...cls],
            })),
          ];

          newRows.forEach((rw) => {
            const newInputs = rw.cols
              .filter(
                (col) =>
                  col.type !== "file" &&
                  col.type !== "camera" &&
                  col?.props?.label
              )
              .map((col) => {
                const rowNumber = rw.rowNumber ? ` (${rw.rowNumber})` : "";
                return {
                  id: getFieldKey({
                    duplicateSectionNumber: l.id,
                    duplicateRowNumber: rw.id,
                    fieldId: col.fieldId,
                  }),
                  title: `${col?.props?.label}${rowNumber}`,
                  type: col.type,
                  source: col?.props?.source,
                  trueDisplay: col?.props?.trueDisplay,
                  falseDisplay: col?.props?.falseDisplay,
                };
              });
            currentSection.inputs = [
              ...(currentSection.inputs || []),
              newInputs,
            ];
          });
        });
        currentSection.inputs = currentSection.inputs?.filter(
          (i) => i?.length > 0
        );
        candidateInfo.push(currentSection);
      });
    });
    return candidateInfo;
  }
};

export const processFormSummary = (formSchema = {}, duplicateFields = {}) => {
  if (!formSchema?.schema) return [];
  if (Object.keys(formSchema?.schema).length > 0) {
    const { sections } = formSchema.schema.layout;
    const dynamicFields = [];
    const getBioData = [sections.find((s) => s.id === 1)];
    getBioData.forEach((sect) => {
      const secRows = !Array.isArray(sect.rows) ? [sect.rows] : sect.rows;
      const duplicateSecIds = duplicateFields?.sections?.[sect.id] || [];
      const newSecs = [
        { id: sect.id, rows: secRows },
        ...duplicateSecIds.map((id, index) => ({
          id,
          sectionNumber: index + 1,
          rows: [...secRows],
        })),
      ];
      newSecs.forEach((l) => {
        const currentSection = {};
        const sectionNumber = l.sectionNumber ? ` (${l.sectionNumber})` : "";
        currentSection.title = `${sect.title}${sectionNumber}`;
        currentSection.description = l.description;

        l.rows.forEach((r) => {
          const cls = !Array.isArray(r.cols) ? [r.cols] : r.cols;

          const duplicateRowIds =
            duplicateFields?.rows?.[`${l.id}-${r.id}`] || [];
          const newRows = [
            { id: r.id, cols: cls },
            ...duplicateRowIds.map((id, index) => ({
              id,
              rowNumber: index + 1,
              cols: [...cls],
            })),
          ];

          newRows.forEach((rw) => {
            const newInputs = rw.cols
              .filter(
                (col) =>
                  col.type !== "file" &&
                  col.type !== "camera" &&
                  col?.props?.label &&
                  isNaN(col.fieldId) !== false
              )
              .map((col) => {
                const rowNumber = rw.rowNumber ? ` (${rw.rowNumber})` : "";
                return {
                  id: getFieldKey({
                    duplicateSectionNumber: l.id,
                    duplicateRowNumber: rw.id,
                    fieldId: col.fieldId,
                  }),
                  title: `${col?.props?.label}${rowNumber}`,
                  type: col.type,
                  source: col?.props?.source,
                  trueDisplay: col?.props?.trueDisplay,
                  falseDisplay: col?.props?.falseDisplay,
                };
              });
            currentSection.inputs = [
              ...(currentSection.inputs || []),
              newInputs,
            ];
          });
        });
        currentSection.inputs = currentSection.inputs?.filter(
          (i) => i?.length > 0
        );
        dynamicFields.push(currentSection);
      });
    });
    return dynamicFields;
  }
};

export const processFormFiles = (formSchema = {}, df = {}) => {
  if (!formSchema?.schema) return [];
  if (Object.keys(formSchema?.schema).length > 0) {
    const { sections } = formSchema.schema.layout;
    let candidateFiles = [];
    sections.forEach((sect) => {
      const secRows = !Array.isArray(sect.rows) ? [sect.rows] : sect.rows;
      const duplicateSecIds = df?.sections?.[sect.id] || [];
      const newSecs = [
        { ...sect, rows: secRows },
        ...duplicateSecIds.map((id, index) => ({
          ...sect,
          id,
          sectionNumber: index + 1,
          rows: [...secRows],
        })),
      ];
      newSecs.forEach((l) => {
        l.rows.forEach((r) => {
          const cls = !Array.isArray(r.cols) ? [r.cols] : r.cols;

          const duplicateRowIds = df?.rows?.[`${l.id}-${r.id}`] || [];
          const newRows = [
            { id: r.id, cols: cls },
            ...duplicateRowIds.map((id, index) => ({
              id,
              rowNumber: index + 1,
              cols: [...cls],
            })),
          ];

          newRows.forEach((rw) => {
            const newInputs = rw.cols
              .filter((col) => col.type === "file" || col.type === "camera")
              .map((col) => {
                const rowNumber = rw.rowNumber ? ` (${rw.rowNumber})` : "";

                return {
                  id: getFieldKey({
                    duplicateSectionNumber: l.id,
                    duplicateRowNumber: rw.id,
                    fieldId: col.fieldId,
                  }),
                  title: `${col.props.label}${rowNumber}`,
                  section: l.title,
                  sectionDescription: l.description,
                  alternateLabel: col.props.alternateLabel || col.props.label,
                  type: col.type,
                };
              });
            candidateFiles = [...candidateFiles, ...newInputs];
          });
        });
      });
    });
    return candidateFiles;
  }
};

export const getColumnStructure = (len) => {
  const colStructure = Array.from(len).map(() => "1fr");
  if (colStructure.length === 2) {
    colStructure.push("1fr");
  }
  return colStructure.join(" ");
};

export const getColumnStructureForPDF = (formData) => {
  const formDataClone = [...formData]
  formDataClone.forEach((formData) => {
    formData.inputs.forEach((formInput) => {
      if (formInput.length === 2) {
        formInput.push(null)
      }
    })
  })
  return formDataClone;
};

export const formatApplicantDate = (date) => {
  var dateMomentObject, dateObject;
  const dateIsValid =
    moment(date).format("MM/DD/YYYY") === "Invalid date" ? false : true;
  if (!dateIsValid) {
    dateMomentObject = moment(date, "DD/MM/YYYY");
    dateObject = dateMomentObject.toDate();
  } else {
    dateObject = moment(date).toDate();
  }

  if (Object.prototype.toString.call(dateObject) === "[object Date]") {
    if (isNaN(dateObject.getTime())) {
      return date;
    } else {
      return dateObject.toString();
    }
  } else {
    return date;
  }
};

export const checkForContent = (inputs, formItemData) => !!inputs?.some(row => row?.some(r => formItemData?.[r?.id]));
