import React from "react";
import {
  Grid,
  Box,
  Layout,
  Tag,
  Spacer,
  Text,
  Button,
} from "@flexisaf/flexibull2";
import { getCountry } from "country-state-picker";
import { Theme } from "utils/theme";
import { stringToHslColor } from "utils/stringToHSLColor";
import styled from "styled-components";
import { FormWrapper } from "./style";
import { checkForContent, getColumnStructure } from "./utils";
import { formatAddress } from "utils/helpers";
import { useSelector } from "react-redux";
import { selectApplications } from "redux/landing/landingSlice";

const moment = require("moment-timezone");

export const FormPreview = ({
  onUpdateForm,
  onSubmitForm,
  processedForm,
  submissionData,
  application,
  courseId,
  processedFormAttachments,
  saving = false,
  schema,
  portalConfig,
  formFiller,
}) => {
  const { userLocale } = useSelector(selectApplications);

  const getCourseDetails = () => {
    const course = application.courses?.find((c) => c.course.id === courseId);
    return `${course?.course.title} - ${course?.course.code}`;
  };
  const Passportdiv = styled.div`
    background: #fff;
    padding: 8px;
    margin-top: 1rem;
  `;
  const Boxflex = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    padding: 30px 40px;
  `;
  const Image = styled.img`
    width: 250px;
    @media (max-width: 1157px) {
      width: 200px;
    }
    @media (max-width: 700px) {
      width: 150px;
    }
  `;
  const Boxspan = styled(Box)`
    @media (max-width: 420px) {
      display: flex;
      justify-content: space-between;
    }
  `;

  const formatCountry = (val) => {
    const findCountry = getCountry(val || "");
    return findCountry?.name || val;
  };

  const filterProcessForm = () => {
    const joinedForm = processedForm.map((form, i) => ({
      ...form,
      ...schema?.layout?.sections[i],
    }));
    const filteredForm = formFiller === "referee" ? joinedForm.filter(
      (form) => form?.refereeSectionType?.toLowerCase() === "referee"
    ) : joinedForm.filter(
      (form) => form?.refereeSectionType?.toLowerCase() !== "referee"
    );
    return filteredForm;
  };

  return (
    <Layout>
      <FormWrapper>
          <Boxflex background="#fff">
            <Box background="#fff">
              <Box margin="10px auto 30px" align="center" vAlign>
                <Text
                  bold
                  block
                  size="25px"
                  color={({ theme }) => theme.PrimaryColor}
                >
                  {" "}
                  Review form
                </Text>
              </Box>
              {formFiller !== "referee" && <Grid
                default="1fr max-content"
                gap="20px"
                templateRows="1fr auto"
              >
                <Grid default="auto max-content" gap="20px">
                  <Box>
                    <Text bold block size="20px">
                      {submissionData?.first_name} {submissionData?.last_name}
                    </Text>
                    <Spacer space="10px" />
                  </Box>
                  <Box style={{ gridColumn: "1/3", marginTop: "1rem" }}>
                    <Spacer space="5px" />
                    <Box margin="0 40px 0 0">
                      <Text
                        size="14px"
                        capitalize
                        color={Theme.PrimaryColor}
                        bold
                      >
                        {application?.title}
                      </Text>
                    </Box>
                    <Box margin="10px 40px 0 0">
                      <Text bold uppercase>
                        {portalConfig?.courseAlias}:
                      </Text>{" "}
                      <Text uppercase>{getCourseDetails()}</Text>
                    </Box>
                    <Box margin="10px 0 0 0">
                      {application?.applicationGroupItems?.map((group, i) => (
                        <Tag
                          key={i}
                          height={25}
                          font="12px"
                          style={{ border: "none" }}
                          color={stringToHslColor(group.name, 100, 30)}
                          fontColor={stringToHslColor(group.name, 30, 40)}
                          spaceRight
                        >
                          {group.name}
                        </Tag>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>}
              <Box></Box>
            </Box>
            {submissionData?.passport_picture && (
              <Passportdiv
                background="#fff"
                margin="auto 0 -15.8rem auto"
                pad="8px"
                style={{ border: "1px solid #D8D8E3", zIndex: 2 }}
              >
                <Image
                  width="250px"
                  src={submissionData?.passport_picture}
                  alt="Passport"
                />
              </Passportdiv>
            )}
          </Boxflex>

        <Box pad="0 10px 10px 40px" className="view-application-box">
          <>
            <Box margin=" 0 30px 0 0">
              <Grid default="1fr 260px">
                <Box />
              </Grid>
            </Box>
            <Box className="applicant-info">
              {filterProcessForm()?.map((item, i) => (
                <Box margin="40px 0 0" pad="20px 0 0" key={i}>
                  <Text block bold capitalize size="16px" uppercase>
                    {item.title}{" "}
                    {checkForContent(item.inputs, submissionData)
                      ? ""
                      : " - (not filled)"}
                  </Text>

                  <Spacer space="30px" />
                  {item?.inputs?.map((eachRow, rowIndex) => (
                    <Grid
                      className="form-grid"
                      default={getColumnStructure(eachRow)}
                      gap="0px"
                      key={`row-${rowIndex}`}
                    >
                      {eachRow?.map((eachField, i) => (
                        <Box
                          key={i}
                          pad="20px 30px 20px 0"
                          className="single-form"
                        >
                          <Text
                            color={({ theme }) => theme.PrimaryInputOutline}
                            uppercase
                            size="13px"
                            bold
                            block
                          >
                            {eachField.title}
                          </Text>
                          <Spacer space="15px" />
                          {eachField?.source === "nigeria-states-lga" ? (
                            <Text capitalize size="15px" block>
                              {formatAddress(submissionData?.[eachField.id])}
                            </Text>
                          ) : eachField?.source === "countries" ? (
                            <Text capitalize size="15px" block>
                              <p>
                                {formatCountry(submissionData?.[eachField?.id])}
                              </p>
                            </Text>
                          ) : (
                            <Text
                              capitalize={
                                !["email", "wysiwyg"].includes(
                                  eachField.type?.toLowerCase()
                                )
                              }
                              size="15px"
                              block
                            >
                              {eachField?.type === "checkbox" ? (
                                <>
                                  {submissionData?.[eachField.id]
                                    ? eachField.trueDisplay
                                    : eachField.falseDisplay}
                                </>
                              ) : (
                                <>
                                  {["date", "single-date"].includes(
                                    eachField.type?.toLowerCase()
                                  ) && submissionData?.[eachField.id] ? (
                                    moment(submissionData?.[eachField.id])
                                      .tz(userLocale || "Africa/Lagos")
                                      .format("DD-MM-YYYY")
                                  ) : (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: submissionData?.[eachField.id],
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </Text>
                          )}
                        </Box>
                      ))}
                    </Grid>
                  ))}
                </Box>
              ))}

              {processedFormAttachments?.find(
                (eachAttachment) =>
                  submissionData?.[eachAttachment.id]?.[0]?.name
              ) && (
                <Box margin="40px 0 0" pad="20px 0 0">
                  <Text block bold capitalize size="16px" uppercase>
                    Attachments <i className="safapply-attach"></i>
                  </Text>

                  <Spacer space="30px" />
                  <Grid default="1fr 1fr 1fr" gap="0px">
                    {processedFormAttachments
                      ?.filter(
                        (eachAttachment) => submissionData?.[eachAttachment.id]
                      )
                      ?.map((eachAttachment, i) => (
                        <Box key={i} pad="20px 30px 20px 0">
                          <Text
                            color={({ theme }) => theme.PrimaryInputOutline}
                            uppercase
                            size="13px"
                            bold
                            block
                          >
                            {eachAttachment?.title}
                          </Text>
                          <Spacer space="15px" />
                          <Text
                            capitalize={
                              !!String(eachAttachment?.type)?.toLowerCase() ===
                              "email"
                            }
                            size="15px"
                            block
                          >
                            {submissionData?.[eachAttachment.id]?.[0]?.name ||
                              eachAttachment.title}
                          </Text>
                        </Box>
                      ))}
                  </Grid>
                </Box>
              )}
            </Box>
          </>
        </Box>
        <Boxspan margin="20px 30px 70px" align="right" className="form-actions">
          <Button
            height={50}
            pale
            color="#ccc"
            width={160}
            spaceRight="40px"
            fontColor={({ theme }) => theme.PrimaryInputOutline}
            iconLeft={<i className="safapply-left-1"></i>}
            onClick={onUpdateForm}
          >
            Update form
          </Button>
          <Button
            height={50}
            bold
            width={140}
            onClick={onSubmitForm}
            progress={saving}
          >
            Submit form
          </Button>
        </Boxspan>
      </FormWrapper>
    </Layout>
  );
};
