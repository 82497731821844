import { Button } from "@flexisaf/flexibull2";
import { Link } from 'react-router-dom';
import styled, { keyframes } from "styled-components";

const HorizontalLoop = keyframes`
    0% {right: -40px;}
    50% {right: -22px;}
    100% {right: -40px;}
`;

export const Header = styled.header`
  background-color: #fff;
  padding: 2rem;

  & .pointer {
    animation: ${HorizontalLoop} 1s linear infinite;
    position: absolute
  }

  @media(max-width: 400px) {
    & .pointer {
      display: none;
    }
  }

  
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 108.5rem;
  margin: 0 auto;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const NavLink = styled(Link)`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.PrimaryGreyDark};
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.PrimaryColor};
  }
`;

export const OrgLogo = styled(Link)`
  max-height: 3rem;
  padding: 0;
  padding: 10px 0;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  color: ${({ theme }) => theme.PrimaryGreyDark};
  @media (max-width: 500px) {
    margin-top: 10px;
  }

  & img {
    object-fit: contain;
    object-position: left;
    height: 100%;
    max-height: 60px;
    max-width: 200px;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 500px) {
    margin-top: 30px;
  }
`;

export const UserBtn = styled(Button)`
  border-width: 2px;
  margin-left: 0;
  border-radius: 0.4rem;
  cursor: pointer;
`;
