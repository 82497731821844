import React from "react";
import { Box, Grid, Spacer, Text, Table } from "@flexisaf/flexibull2";
import { useSelector } from "react-redux";
import { selectNeco } from "redux/neco/necoSlice";
import { findGradeRemark } from "components/ApplicantPreview/NecoResult/necoUtils";
import { NecoWrapper } from "./neco.style";
import { NecoResultMainWrapper } from "./neco.style";

const NecoVerificationCandidateResult = () => {
  const { allCandidateResult, allExams, allCandidateInfo } =
    useSelector(selectNeco);
  return (
    <Box>
      <NecoResultMainWrapper>
        <Box style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <Box
            pad="20px 50px"
            width="50vw"
            margin="auto"
            style={{ textAlign: "left" }}
          >
            <Box margin="20px 0 0">
              <Grid default="repeat(3, 1fr)">
                <Box margin="0 0 24px">
                  <Text bold Block>
                    First Name
                  </Text>
                  <Spacer space="12px" />
                  <Text Block>{allCandidateResult[0]?.firstName}</Text>
                </Box>
                <Box>
                  <Text bold Block>
                    Middle Name
                  </Text>
                  <Spacer space="12px" />
                  <Text Block>{allCandidateResult[0]?.middleName}</Text>
                </Box>
                <Box>
                  <Text bold Block>
                    Last Name
                  </Text>
                  <Spacer space="12px" />
                  <Text Block>{allCandidateResult[0]?.lastName}</Text>
                </Box>
              </Grid>
              <Grid default="repeat(3, 1fr)">
                <Box>
                  <Text bold Block>
                    Date of birth
                  </Text>
                  <Spacer space="12px" />
                  <Text Block>{allCandidateInfo[0]?.dob}</Text>
                </Box>
                <Box>
                  <Text bold Block>
                    Examination Number
                  </Text>
                  <Spacer space="12px" />
                  <Text Block>{allCandidateResult[0]?.registrationNumber}</Text>
                </Box>
                <Box>
                  <Text bold Block>
                    Exam year
                  </Text>
                  <Spacer space="12px" />
                  <Text Block>{allExams[0]?.examYear}</Text>
                </Box>
              </Grid>
            </Box>
          </Box>
          <Box>
            <NecoWrapper>
              <Table data={allCandidateResult[0]?.subjects}>
                <table>
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Grade</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCandidateResult[0]?.subjects?.map((subject, i) => (
                      <tr key={i}>
                        <td>
                          {subject.name} ({subject.shortName})
                        </td>
                        <td style={{ textTransform: "uppercase" }}>
                          {subject.grade}
                        </td>
                        <td style={{ textTransform: "uppercase" }}>
                          {findGradeRemark(subject.grade)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Table>
            </NecoWrapper>
          </Box>
          <Spacer space="32px" />
        </Box>
      </NecoResultMainWrapper>
    </Box>
  );
};

export default NecoVerificationCandidateResult;
