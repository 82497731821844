import styled from "styled-components";

export const ModalWrapper = styled.div`
  & .flexi-tab-header {
    border-bottom: none;
    margin-top: 30px;
    font-size: 12px;

    & > .flexi-tab-label-active {
      border: 2px solid ${(props) => props.theme.PrimaryColor};
      color: ${(props) => props.theme.PrimaryColor};
      padding: 10px 18px;
      font-weight: 600;
      border-radius: ${(props) => props.theme.PrimaryRadius};
    }
  }

  & .logo-buttons {
    margin-left: auto;
    width: fit-content;
    grid-template-columns: max-content max-content;

    img {
      height: 16px;
    }
  }

  & .flexi-tab-content-active {
    & .flexi-checkbox {
      padding: 10px;
    }
  }

  & .flexi-modal-body {
    max-width: max-content;

    @media (max-width: 500px) {
      max-width: 100%;

      & .free-payment {
        display: block;
      }
    }

    & .parent-modal {
      padding: 50px;

      @media (max-width: 500px) {
        padding: 30px 40px;
      }

      & .info-grid {
        margin: 24px 0 0 0;
        grid-template-columns: 1fr;

        & > div {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 10px;
        }

        @media (max-width: 500px) {
          grid-template-columns: 1fr;
        }
      }

      & .flexi-input-affix-wrapper {
        width: 100%;
      }

      & .accept-pay {
        width: 100%;
      }
    }
  }
`;

export const PaymentNotice = styled.div`
  & .main-form {
    box-shadow: #a7a7a791 0px 2px 10px 0px;
    display: grid;
    place-items: center;
    border-radius: 5px;
  }
`;
