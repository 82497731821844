import { Notify } from "@flexisaf/flexibull2/build/notify";
import { createSlice } from "@reduxjs/toolkit";

import { orgConfig } from "utils/orgConfig";

import request from "utils/request";
import { clearLocalAccessToken, clearLocalRefreshToken, clearLocalUser, getLocalUser, getPhone, setLocalAccessToken, setLocalRefreshToken, setLocalUser } from "utils/helpers";
import config from "utils/config";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    saving: false,
    loading: false,
    user: null,
    error: null,
  },
  reducers: {
    confirmUserStart: (state, { payload }) => {
      state.saving = true;
    },
    confirmUserSuccess: (state, { payload }) => {
      state.saving = false;
      Notify("Verification Successful", {
        position: "top-right",
        status: "success",
      });
    },
    confirmUserFail: (state, { payload }) => {
      state.saving = false;
      state.error = payload;
      Notify(payload, {
        position: "top-right",
        status: "error",
      });
    },
    startProfileUpdate: (state, { payload }) => {
      state.saving = true;
    },
    successfulProfileUpdate: (state, { payload }) => {
      const user = JSON.parse(getLocalUser());
      const contactClone = [...user.contacts];
      const findPhoneNumber = contactClone.find(
        (contact) => contact.contactType === "PHONE_NUMBER"
      );
      if (findPhoneNumber) {
        findPhoneNumber.contact = payload.phone_numbers[0];
      } else {
        contactClone.push({
          contact: payload.phone_numbers[0],
          contactType: "PHONE_NUMBER",
        });
      }
      const newUpdate = {
        ...user,
        firstName: payload.first_name,
        lastName: payload.last_name,
        otherName: payload.other_name,
        contacts: [...contactClone],
      };
      setLocalUser(JSON.stringify(newUpdate))
      state.user = {
        ...state.user,
        firstName: payload.first_name,
        lastName: payload.last_name,
        otherName: payload.other_name,
        contacts: [...contactClone],
      };
      state.saving = false;
    },
    failedProfileUpdate: (state, { payload }) => {
      state.saving = false;
    },
    registerStart: (state, { payload }) => {
      state.saving = true;
    },
    registerSuccess: (state, { payload }) => {
      state.saving = false;
      Notify("Registered Successfully", {
        position: "top-right",
        status: "success",
      });
    },
    registerFail: (state, { payload }) => {
      state.saving = false;
      state.error = payload;
      Notify(payload, {
        position: "top-right",
        status: "error",
      });
    },
    loginStart: (state, { payload }) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    },
    loginSuccess: (state, { payload }) => {
      state.loading = false;
      state.user = payload;
      state.error = null;
      const phoneExists = getPhone(payload);
      Notify(phoneExists ? "Login Successful" : "Registered Successfully", {
        position: "top-right",
        status: "success",
      });
    },
    loginFail: (state, { payload }) => {
      state.loading = false;
      state.user = null;
      state.error = payload;
      Notify(payload, {
        position: "top-right",
        status: "error",
      });
    },
    loggedInUserClear: (state) => {
      state.loading = false;
      state.user = null;
    },
    loggedInUserSet: (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    },
    setAuthError: (state, { payload }) => {
      state.error = payload;
    },
    changePasswordStart: (state, { payload }) => {
      state.loading = true;
    },
    changePasswordSuccess: (state, { payload }) => {
      state.loading = false;
      Notify("Password changed successfully", {
        position: "top-right",
        status: "success",
      });
    },
    changePasswordFail: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const {
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFail,
  confirmUserStart,
  confirmUserSuccess,
  confirmUserFail,
  startProfileUpdate,
  successfulProfileUpdate,
  failedProfileUpdate,
  registerStart,
  registerSuccess,
  registerFail,
  loginStart,
  loginSuccess,
  loginFail,
  loggedInUserClear,
  loggedInUserSet,
  setAuthError,
} = authSlice.actions;

export const candidateLogin =
  (credentials, lg, verifyAcc) => async (dispatch) => {
    await dispatch(loginStart());
    const url = `${config.UMS_BASE_URL}/auth/products/${orgConfig.product}/organisations/${credentials.organisation}/login`;
    const response = await request({
      method: "post",
      data: credentials,
      url,
    });
    if (response.success) {
      setLocalAccessToken(response.raw.access_token);
      setLocalRefreshToken(response.raw.refresh_token);
      setLocalUser(JSON.stringify(response.raw.user));

      await dispatch(loginSuccess(response.raw.user));
      lg(response.raw.user);
    } else {
      if (response.message === "User must be verified") {
        verifyAcc(response.returnedUserId);
      }
      dispatch(loginFail(response.message || "unable to login"));
    }
  };

export const candidateEditProfile = (userDetails, cb) => async (dispatch) => {
  await dispatch(startProfileUpdate());
  const url = `${config.UMS_BASE_URL}/users/me`;
  const response = await request({
    method: "put",
    data: userDetails,
    url,
  });
  if (response.success) {
    Notify("Profile updated succesfully", {
      position: "top-right",
      status: "success",
    });
    await dispatch(successfulProfileUpdate(userDetails));
    cb();
  } else {
    await dispatch(failedProfileUpdate());
  }
};

export const candidateRequestResetPassword = async (data, cb) => {
  const url = `${config.UMS_BASE_URL}/auth/products/${orgConfig.product}/organisations/${data.organisation}/password/reset-request`;
  const response = await request({
    method: "post",
    data,
    url,
  });
  if (response.success) {
    Notify("Password reset submitted successfully, Please check your mail", {
      position: "top-right",
      status: "success",
    });
    cb();
  } else {
    const errorMessage = response.statusCode === 404 ? "Email account not registered" : response.message;
    Notify(errorMessage, {
      position: "top-right",
      status: "error",
    });
  }
};

export const candidateResetPassword = (data, cb) => async (dispatch) => {
  await dispatch(changePasswordStart());
  const url = `${config.UMS_BASE_URL}/auth/products/${orgConfig.product}/organisations/${data.organisation}/password/reset`;
  const response = await request({
    method: "post",
    data,
    url,
  });
  if (response.success) {
    await dispatch(changePasswordSuccess());
    cb();
  } else {
    await dispatch(changePasswordFail());
    const errorMessage = response?.message || "unable to change password";
    Notify(errorMessage, {
      position: "top-right",
      status: "error",
    });
  }
};

export const resendActivationToken = (data) => async (dispatch) => {
  const url = `${config.UMS_BASE_URL}/auth/products/${orgConfig.product}/organisations/${data.organisation}/resend-confirm`;
  const response = await request({
    method: "post",
    data,
    url,
  });
  if (response.success) {
    Notify("Activation token successfully sent", {
      position: "top-right",
      status: "success",
    });
  } else {
    const errorMessage = response?.message || "unable to send activation token";
    Notify(errorMessage, {
      position: "top-right",
      status: "error",
    });
  }
};

export const candidateRegister = (details, cb) => async (dispatch) => {
  await dispatch(registerStart());
  const url = `${config.API_BASE_URL}/applicants/register`;
  const response = await request({
    method: "post",
    data: details,
    url,
  });
  if (response.success) {
    await dispatch(registerSuccess());
    cb(response.raw);
  } else {
    if (response.statusCode === 400) {
      dispatch(registerFail("Email already exists"));
    } else {
      dispatch(registerFail(response.message || "Something went wrong"));
    }
  }
};

export const confirmUser = (details, cb) => async (dispatch) => {
  await dispatch(confirmUserStart());
  const url = `${config.UMS_BASE_URL}/auth/products/${orgConfig.product}/organisations/${details.organisation}/confirm`;
  const response = await request({
    method: "post",
    data: {
      token: details.token,
      user_id: details.id,
    },
    url,
  });
  if (response.success) {
    await dispatch(confirmUserSuccess());
    cb(response.raw);
  } else { 
    const errorMessage = response.statusCode === 600 ? response.message : "Invalid Token";
    await dispatch(confirmUserFail(errorMessage));
  }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    const user = getLocalUser();
    dispatch(loggedInUserSet(JSON.parse(user)));
  } catch (error) {
    dispatch(loggedInUserClear(error));
  }
};

export const candidateLogout = (cb) => async (dispatch) => {
  try {
    clearLocalAccessToken();
    clearLocalRefreshToken();
    clearLocalUser();
    dispatch(loggedInUserClear());
    cb();
  } catch (error) {
    dispatch(setAuthError(error));
  }
};

export const googleAuth = (callback_url, org) => async (dispatch) => {
  const url = `${config.UMS_BASE_URL}/oauth2/google/authorize?callback_url=${callback_url}&organization=${org}&product=${orgConfig.product}`;
  window.location.href = url;
};

export const getUserDetails = (code, orgId) => async (dispatch) => {
  const url = `${config.UMS_BASE_URL}/auth/products/${orgConfig.product}/organisations/${orgId}/transfer`;
  const response = await request({ method: "post", url, data: { code } });
  if (response.success) {
    await dispatch(loginSuccess(response.raw.user));
    setLocalAccessToken(response.raw.access_token);
    setLocalRefreshToken(response.raw.refresh_token);
    setLocalUser(JSON.stringify(response.raw.user));
  }
  return response;
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectAuth = (state) => state.auth;
export const selectAuthUser = (state) => state.auth.user;

export default authSlice.reducer;
