import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { PersistGate } from 'redux-persist/integration/react';


import {
  initializeServiceWorker,
  updateServiceWorker,
} from "redux/landing/landingSlice";

import {store, persistor} from "redux/store";
import * as serviceWorker from "./serviceWorker";
import "index.css";

const render = () => {
  const queryClient = new QueryClient();
  const App = require("./App").default;

  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}

serviceWorker.register({
  onSuccess: () => store.dispatch(initializeServiceWorker()),
  onUpdate: (registration) => store.dispatch(updateServiceWorker(registration)),
});
