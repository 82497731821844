import React from 'react';
import { Box, Button, Text, Spacer } from "@flexisaf/flexibull2";
import { selectAuth } from "redux/auth/authSlice";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { BannerStyle } from './layout.style';
import { selectApplications } from 'redux/landing/landingSlice';

export const Banner = () => {
  const { params } = useRouteMatch();
  const history = useHistory();
  const { user } = useSelector(selectAuth);
  const { portalConfig } = useSelector(selectApplications);
  return (
    <BannerStyle>
      <Box className="banner">
        <Box margin="" >
          <Text bold size="15px">Hi {user.firstName},</Text>
          <Spacer space="15px"/>
          <Text block>Welcome to your application portal</Text>
          <Spacer space="5px"/>
          <Text block>
            Click on the application box below to continue editing or to
            review and print the completed form.
          </Text>
        </Box>
        <Button
          className="search-course"
          onClick={() => history.push(`/${params.orgName}/search`)}
          iconRight={<i className="safapply-search-2"></i>}
          id="banner-section"
        >
          Search for {portalConfig?.courseAlias}
        </Button>
      </Box>
    </BannerStyle>
  )
};

export default Banner;
