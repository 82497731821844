import styled from 'styled-components';
import { Box } from '@flexisaf/flexibull2'

export const OrgSelectWrapper = styled(Box)`
  & .flexi-login-right-cage {
    padding: 0 !important;

    & .flexi-login-right-top {
      
      padding: 40px 20px 0;
      @media (max-width: 500px) {
        text-align: left;
      }
    }

    & .flexi-login-body {
      @media (max-width: 500px) {
        display: block;
      }
    }
  }

  form {
    display: block;
    position: relative;
    margin: 30px 0;
    padding: 0 20px 0 0;

    input{
      height: 45px;
      width: 100%;
      display: block;
      border-radius: 5px;
      padding: 5px 20px;
      font-weight: normal;
      font-size: 1.2em;
      border: 1px solid #9D9DB7;
      color: #707070;
      transition: all 0.4s ease-out;

      &::placeholder{
          font-weight: 400;
          opacity: 0.7;
      }
      &:focus{
          outline: none;
          box-shadow: 0 1px 3px #9D9DB7;    
      }

      @media (max-width: 500px){
        width: 92%;
      }
      
    }

    i {
      position: absolute;
      right: 25px;
      top: 30px;
      color: #707070;
      cursor: pointer;
      background: #fff;
      padding: 10px;
    }
    
  }

  ul {
    padding: 0 20px;
    list-style-type: circle;

    li {
      margin: 25px 0;

      &::marker {
        color: #707070;
        font-size: 20px;
      }
      
      a{
        color: #707070;
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }

  a {
    text-decoration: none;
  }
`

export const BlankPage = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
`;
