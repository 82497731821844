import styled, { keyframes } from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
`;

export const MainWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 0 1.2rem;
  flex-direction: column;

  & .tabs-wrapper {
    & > div {
      @media (max-width: 500px) {
        display: grid;

        & > .flexi-tab-header {
          grid-row: 2;
          position: fixed;
          z-index: 99;
          background: #fff;
          width: 100%;
          margin: -18px;
          place-content: center;
          bottom: 19px;
          box-shadow: 0px -3px 20px 2px #1c1a461a;
        }
      }
    }
  }

  & .flexi-tab-label-active {
    font-weight: 600;
    color: ${({ theme }) => darken(0.1, theme.PrimaryColor)};
    padding: 20px;
    font-size: 13px;
  }

  & .flexi-tab-inactive {
    padding: 20px;
  }

  .grid-container {
    display: grid;
    grid-gap: 1.2rem;

    @media screen and (max-width: 500px) {
      grid-gap: 0;
      grid-template-columns: 1fr auto;
    }
  }

  & .flexi-table {
    overflow-x: unset;
  }
`;

export const pulseAnimation = keyframes`
 0% { transform: scale(0.9); box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7) }
 70% { transform: scale(1); box-shadow: 0 0 0 10px rgba(142, 68, 173, 0) }
 100% { transform: scale(0.9); box-shadow: 0 0 0 0 rgba(142, 68, 173, 0) }
`

export const BannerStyle = styled.div`
  & .banner {
    ${(props) => props.theme.Elevate["low"]};
    background: #ffffff;
    padding: 1.5rem;
    height: fit-content;
    display: block;
    margin-bottom: 1.2rem;
    border-radius: ${({ theme }) => theme.SecondaryRadius};
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .search-course {
      margin: auto 0;
      transform: scale(1);
      animation: ${pulseAnimation} 2s infinite;
      @media (max-width: 1000px) {
        margin-top: 20px;
      }
    }
  }
`;

export const Avatar = styled.div`
  & img {
    display: inline-block;
    overflow: hidden;
    margin: auto 0;
    line-height: 1;
    height: 40px;
    vertical-align: middle;
    border-radius: 50%;
  }
`;

export const HeaderWrapper = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  background: #ffffff;
  border-radius: 5px;
  padding: 0.6rem 1rem;
  margin-top: 20px;
  margin-bottom: 20px;
  ${(props) => props.theme.Elevate["low"]};

  & .right-nav {
    margin: auto 0;
  }

  & .left-nav img {
    height: 2.5rem;
    width: auto;
  }
`;
